import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const learningObjectiveColumns = [
  "lo_code",
  "Grade",
  "Domain",
  "lo_description_ar",
  "Axis",
  "topic_ar",
  "lo_description_en"
];

function LearningObjectiveUpload({}) {
  return (
    <BulkUpload
      fileColumns={learningObjectiveColumns}
      sheetName="lo-upload-new"
    />
  );
}

export default LearningObjectiveUpload;
