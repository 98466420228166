class CacheService {
  onSetResult = (result, key) => {
    localStorage.setItem(key, JSON.stringify(result));
    return result;
  }
  onGetResult = (key) => {
    return null
    // const cachedHits = localStorage.getItem(key);
    // if (cachedHits !== "undefined" && cachedHits) {
    //   return JSON.parse(cachedHits);
    // } else return null
  }
  onGetItem = (key) => {
    const cachedHits = localStorage.getItem(key);
    if (cachedHits !== "undefined" && cachedHits) {
      return JSON.parse(cachedHits);
    } else return null
  }
  onRemoveKey = key => {
    return localStorage.removeItem(key);
  }
}

export default new CacheService();
