import API from './api';
import CacheService from './CacheService'
import { LEARNING_OBJECTIVES_URL, LEARNING_OBJECTIVE_URL, LO_CONTENT_URL } from '../constants'

class LearningObjectiveService {
  getLearningObjective = async () => {
    const data = CacheService.onGetResult(LEARNING_OBJECTIVES_URL)
    if (!data) {
      return API
        .get(LEARNING_OBJECTIVES_URL)
        .then(response => CacheService.onSetResult(response.data, LEARNING_OBJECTIVES_URL))
        .catch(error => error.response)
    }
    return data
  };
  addLearningObjective = async (data) => {
    return API
      .post(LEARNING_OBJECTIVE_URL, data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  updateLearningObjective = async (data) => {
    return API
      .put(LEARNING_OBJECTIVE_URL, data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  deleteLearningObjective = async (learningObjectiveId) => {
    return API
      .delete(`${LEARNING_OBJECTIVE_URL}/${learningObjectiveId}`)
      .then(response => response.data)
      .catch(error => error.response)
  };
  getContentByLearningObjective = async (learningObjectiveId) => {
    const data = CacheService.onGetResult(`${LO_CONTENT_URL}/${learningObjectiveId}/contentmetadata`)
    if (!data) {
      return API
        .get(`${LO_CONTENT_URL}/${learningObjectiveId}/contentmetadata`)
        .then(response => CacheService.onSetResult(response.data, `${LO_CONTENT_URL}/${learningObjectiveId}/contentmetadata`))
        .catch(error => error.response)
    }
    return data
  };
  getContentByLearningObjectiveById = async (learningObjectiveId) => {
    const data = CacheService.onGetResult(`${LO_CONTENT_URL}/${learningObjectiveId}/content`)
    if (!data) {
      return API
        .get(`${LO_CONTENT_URL}/${learningObjectiveId}/content`)
        .then(response => CacheService.onSetResult(response.data, `${LO_CONTENT_URL}/${learningObjectiveId}/content`))
        .catch(error => error.response)
    }
    return data
  };
  getContentByLessonId = async (lessonContentId) => {
    return API
      .get(`/ams/lesson/${lessonContentId}/contents`)
      .then(response => response.data)
      .catch(error => error.response)
  };
}

export default new LearningObjectiveService();
