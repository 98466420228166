import Joi from "joi";

// const emailLetters = new RegExp("^[a-zA-Z0-9._@]+$");
const numberPattern = new RegExp("^[0-9]*$");

const validationSchema = {
  firstName: Joi.string().trim().required().messages({
    "string.empty": "First Name is required",
  }),
  lastName: Joi.string().trim().required().messages({
    "string.empty": "Last Name is required",
  }),
  schoolName: Joi.string().trim().required().messages({
    "string.empty": "School Name is required",
  }),
  emailId: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    // .pattern(emailLetters)
    .messages({
      "string.empty": "Email Id is required",
      "string.email": "Email Id is required",
      "string.pattern.base": "Please enter valid email",
    }),
  mobileNumber: Joi.string()
    .trim()
    .required()
    .pattern(numberPattern)
    .min(6)
    .max(25)
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Only numbers are allowed",
      "string.max": "Maximum length should be 25 digits",
      "string.min": "Minimum length should be 6 digits",
    }),
  principalFirstName: Joi.string().messages({
    "string.empty": "Principal First Name is required",
  }),
  principalLastName: Joi.string().messages({
    "string.empty": "Principal Last Name is required",
  }),
  principalEmailId: Joi.string()
    .allow("")
    .trim()
    .email({ tlds: { allow: false } })
    // .pattern(emailLetters)
    .messages({
      "string.empty": "Email Id is required",
      "string.email": "Email Id is required",
      "string.pattern.base": "Please enter valid email",
    }),
  principalMobileNumber: Joi.string()
    .allow("")
    .trim()
    .pattern(numberPattern)
    .min(6)
    .max(25)
    .messages({
      "string.empty": "Phone number is required",
      "string.pattern.base": "Only numbers are allowed",
      "string.max": "Maximum length should be 25 digits",
      "string.min": "Minimum length should be 6 digits",
    }),
  educationalYearStartDate: Joi.string().required().trim().messages({
    "string.empty": "Educational year start date is required",
  }),
  educationalYearEndDate: Joi.string().required().trim().messages({
    "string.empty": "Educational year end date is required",
  }),
  term1: Joi.string().required().trim().messages({
    "string.empty": "Term one date is required",
  }),
};

const requiredSchema = {
  emailId: Joi.string().allow(""),
  mobileNumber: Joi.when("emailId", {
    is: "",
    then: Joi.string(),
    otherwise: Joi.string().allow(""),
  }),
};

export { validationSchema, requiredSchema };
