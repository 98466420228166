const lang = localStorage.getItem("language");
const layoutsStyle = theme => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  fullPage: {
    // padding: "40px 0",
    position: "relative",
    // minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: "rgba(238, 238, 238, 1)",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    // height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "fit-content!important"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      //backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    },
    direction: lang === "ar" ? "rtl" : "ltr"
  },
  divLogo: {
    paddingTop: "60px",
    textAlign: "center",
    "&>img": {
      width: "200px"
    }
  }
});

export default layoutsStyle;
