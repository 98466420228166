import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const baselineOfflineColumns = [
  "GRADE_CODE",
  "DOMAIN_NAME",
  "LO_CODE",
  "LO_DESCRIPTION_AR",
  "BASELINE_NAME_AR",
  "BASELINE_ASSESSMENT_CONTENT_ID"
];

function BaselineOfflineUpload({}) {
  return (
    <BulkUpload
      fileColumns={baselineOfflineColumns}
      sheetName="OfflineBaseline"
    />
  );
}

export default BaselineOfflineUpload;
