import React from "react";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
  },
  datePicker: {
    padding: "0.7rem",
    border: "1px solid rgba(217, 216, 216, 1)",
    borderRadius: "0.5rem",
    width: "100%",
  },
  popper: {
    zIndex: 9,
    "& [class='react-datepicker__time-box']": {
      borderRight: "1px solid #aeaeae",
    },
  },
  "react-datepicker__day--selected": {
    background: "rgb(98 195 238)",
  },
  calender: {
    fontFamily: "dubai-regular",
    "& [class*='selected']": {
      background: "rgb(98 195 238) !important",
    },
  },
  week: {
    margin: "0 0.5rem",
  },
  day: {
    fontWeight: "bold",
    margin: "0 0.5rem",
    "&:hover": {
      background: "rgb(98 195 238)",
      color: "rgb(255 255 255)",
    },
  },
  error: {
    border: "1px solid #f44336",
  },
});

function DateTimePicker({
  startDate,
  setStartDate,
  minDate = null,
  maxDate = null,
  placeHolder = null,
  error = false,
}) {
  const classes = useStyles();
  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      selected={startDate}
      className={classNames(error && classes.error, classes.datePicker)}
      onChange={setStartDate}
      dateFormat="dd/MM/yyyy"
      wrapperClassName={classes.wrapper}
      popperClassName={classes.popper}
      dayClassName={() => classes.day}
      calendarClassName={classes.calender}
      weekDayClassName={() => classes.week}
      placeholderText={placeHolder}
    />
  );
}

DateTimePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  setStartDate: PropTypes.func,
};

export default DateTimePicker;
