import React from "react";
import UserService from "../../../services/UserService";
import Register from "./Register";
import { removeTrailingZero } from "../../../core/selectors";
import Otp from "./Otp";
import phoneCode from "../../../common/activePhoneCode";
import withStyles from "@material-ui/core/styles/withStyles";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "./LoginAndRegistration.json";

import registerStyle from "./registerStyle";

const options = phoneCode.map((isdCode) => {
  return {
    label: isdCode.dial_code,
    value: isdCode.dial_code,
  };
});

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const messages = defineMessages(Translations);

class LoginAndRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      direction:
        localStorage.getItem("direction") === null
          ? "rtl"
          : localStorage.getItem("direction"),
      language:
        localStorage.getItem("language") === null
          ? "ar"
          : localStorage.getItem("language"),
      countryCode: "971",
      selectedOption: { label: "+971", value: "+971" },
      mobileNumber: "",
      emailId: "",
      mobileNumberState: "",
      emailIdState: "",
      showSnackBar: false,
      snackBarMessage: "",
      showSnackBarSuccess: false,
      snackBarSuccessMessage: "",
      nextAction: "",
      userOnboardingType: "",
      otp: "",
      userId: "",
      otpType: "",
      contactType: localStorage.getItem("contactType"),
      snackBarColor: "",
    };
    this.handleCountryInput = this.handleCountryInput.bind(this);
    //this.checkUserAndSendOtp = this.checkUserAndSendOtp.bind(this);
    this.change = this.change.bind(this);
  }

  handleCountryInput = (selectedOption) => {
    this.setState({
      selectedOption,
      countryCode: selectedOption.value.replace("+", ""),
    });
  };

  hideSnackBar = () => {
    this.setState({
      showSnackBar: false,
      snackBarMessage: "",
    });
  };

  checkUserAndSendOtp = (event) => {
    event.preventDefault();
    const {
      intl: { formatMessage },
    } = this.props;
    if (this.isLoginValidated()) {
      const { countryCode, mobileNumber, emailId } = this.state;
      let data = {};
      if (mobileNumber === "") {
        localStorage.setItem("email", emailId);
        localStorage.setItem("contactType", "email");
        data = {
          _contactType: "email",
          _emailOrMobile: emailId,
        };
      } else {
        localStorage.setItem("mobileNumber", mobileNumber);
        localStorage.setItem("countryCode", countryCode);
        localStorage.setItem("contactType", "mobile");
        data = {
          _contactType: "mobile",
          _emailOrMobile: countryCode + mobileNumber,
        };
      }
      UserService.checkUserAndSendOtp(data)
        .then((response) => {
          if (response && parseInt(response._statusCode) === 200) {
            this.setState({ userId: response._entity._id });
            localStorage.setItem("userId", response._entity._id);
            localStorage.setItem("userName", response._entity._name);
            if (
              response._entity._nextAction === "REGISTER" ||
              ["SYSTEM_ADMIN", "CONTENT_MODERATOR"].indexOf(
                response._entity._type
              ) <= -1
            ) {
              this.setState(
                {
                  snackBarMessage: "عذرًا ، الحساب متاح للمشرف الفائق فقط",
                  snackBarColor: "danger",
                },
                () => {
                  this.showNotification();
                }
              );
            } else {
              this.setState({
                userOnboardingType: "LOGIN",
                otpType: "LOGIN",
              });
            }
          } else if (response && parseInt(response._statusCode) >= 400) {
            this.setState(
              {
                snackBarMessage: formatMessage(
                  messages.loginAndRegistration.notRegisteredUser
                ),
                snackBarColor: "danger",
              },
              () => {
                this.showNotification();
              }
            );
          } else throw response;
        })
        .then((_) => this.setState({ showLoader: false, open: false }))
        .catch((e) => console.log(e));
    }
  };

  onOtpChange = (otp) => {
    if (otp.toString().length === 1) otp = `000${otp}`;
    else if (otp.toString().length === 2) otp = `00${otp}`;
    else if (otp.toString().length === 3) otp = `0${otp}`;
    this.setState({
      otp: otp,
    });
  };

  resendOtp = () => {
    const { countryCode, mobileNumber, emailId } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    let data = {};
    if (mobileNumber === "") {
      localStorage.setItem("email", emailId);
      localStorage.setItem("contactType", "email");
      data = {
        _email: emailId,
      };
    } else {
      localStorage.setItem("mobileNumber", mobileNumber);
      localStorage.setItem("countryCode", countryCode);
      localStorage.setItem("contactType", "mobile");
      data = {
        _mobileNumber: countryCode + mobileNumber,
      };
    }
    UserService.resendPassword(data)
      .then((response) => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState(
            {
              otp: "",
              showSnackBarSuccess: true,
              snackBarSuccessMessage: formatMessage(
                messages.loginAndRegistration.otpResendMessage
              ),
              snackBarColor: "success",
            },
            () => {
              this.showSuccessNotification();
            }
          );
        } else throw response;
      })
      .then((_) => this.setState({ showLoader: false, open: false }))
      .catch((e) => console.log(e));
  };

  verifyOtp = (event) => {
    event.preventDefault();
    const {
      intl: { formatMessage },
    } = this.props;
    if (this.isOtpValidated()) {
      const { otp, otpType, userId, emailId, mobileNumber, countryCode } =
        this.state;
      let _contactType, _emailOrMobile;
      if (mobileNumber === "") {
        _contactType = "email";
        _emailOrMobile = emailId;
      } else {
        _contactType = "mobile";
        _emailOrMobile = countryCode + mobileNumber;
      }
      const otpData = {
        _pin: otp,
        _otpType: otpType,
        _userId: userId,
        _contactType: _contactType,
        _emailOrMobile: _emailOrMobile,
      };
      UserService.verifyOtp(otpData)
        .then((response) => {
          if (response && parseInt(response._statusCode) === 200) {
            const {
              _token,
              _schoolId,
              _classIds,
              _userTypeId,
              _teacherId,
              _refreshToken,
            } = response && response._entity;
            localStorage.setItem("abjadToken", _token);
            localStorage.setItem("refreshToken", _refreshToken);
            localStorage.setItem("schoolId", _schoolId);
            _classIds && localStorage.setItem("classIds", _classIds);
            localStorage.setItem("roleId", _userTypeId);
            localStorage.setItem("teacherId", _teacherId);
            if (_userTypeId == 6) {
              this.props.history.push("/schools");
            } else {
              this.props.history.push("/content-metadata");
            }
          } else if (response && parseInt(response._statusCode) === 400) {
            this.setState(
              {
                otp: "",
                snackBarMessage: formatMessage(
                  messages.loginAndRegistration.wrongOtpError
                ),
                snackBarColor: "danger",
              },
              () => {
                this.showNotification();
              }
            );
          } else if (response && parseInt(response._statusCode) === 404) {
            this.setState(
              {
                otp: "",
                snackBarMessage: formatMessage(
                  messages.loginAndRegistration.notRegisteredUser
                ),
                snackBarColor: "danger",
              },
              () => {
                this.showNotification();
              }
            );
          }
        })
        .then((_) => this.setState({ showLoader: false, open: false }))
        .catch((e) => console.log(e));
    }
  };

  verifyLength(value, length) {
    if (value.trim().length >= length) {
      return true;
    }
    return false;
  }

  verifyMobileNumber(value, length) {
    if (value.trim().length < length || value.trim().length > 13) {
      return false;
    } else {
      return true;
    }
  }

  verifyEmail(value) {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    )
      return true;
    else return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: removeTrailingZero(event.target.value) });
        break;
      case "mobileLength":
        if (this.verifyMobileNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: removeTrailingZero(event.target.value) });
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "select":
        this.setState({ [stateName + "State"]: "success" });
        this.setState({ [stateName]: event.target.value });
        break;
      default:
        break;
    }
  }

  formatText = (e) => {
    if (e.key === "Enter") return true;
    const NUMBER_DOT_COMMA = /^[\d]*$/;
    const fieldValue = e.target.value;
    if (!NUMBER_DOT_COMMA.test(e.key) || fieldValue.length > 12)
      e.preventDefault();
  };

  handleKeyDown(event) {
    if (event.key === "e" || event.key === "+") {
      event.preventDefault();
    }
  }

  handleKeyPress(event) {
    var value = event.target.value;
    if (value.length > 12) {
      event.preventDefault();
    }
  }

  isLoginValidated = () => {
    const { mobileNumberState, emailIdState } = this.state;
    if (mobileNumberState === "success" || emailIdState === "success") {
      return true;
    } else {
      this.setState(
        {
          snackBarMessage: "يرجي ادخال رقم هاتف او بريد الكتروني صحيح",
          snackBarColor: "danger",
        },
        () => {
          this.showNotification();
        }
      );
      if (mobileNumberState !== "success") {
        this.setState({ mobileNumberState: "error" });
      }
      if (emailIdState !== "success") {
        this.setState({ emailIdState: "error" });
      }
    }
    return false;
  };

  showNotification() {
    this.setState({ showSnackBar: true });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showSnackBar: false });
      }.bind(this),
      5000
    );
  }

  showSuccessNotification() {
    this.setState({ showSnackBarSuccess: true });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showSnackBarSuccess: false });
      }.bind(this),
      5000
    );
  }

  isOtpValidated = () => {
    const { otp } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    if (otp) return true;
    else
      this.setState(
        {
          snackBarMessage: formatMessage(
            messages.loginAndRegistration.wrongOtpError
          ),
          snackBarColor: "danger",
        },
        () => {
          this.showNotification();
        }
      );
    return false;
  };

  goBack = () => {
    const { otpType } = this.state;
    if (otpType === "REGISTER") {
      this.setState({
        userOnboardingType: "REGISTER",
      });
    } else {
      this.setState({
        userOnboardingType: "",
      });
    }
  };

  changeMobileNumber = () => {
    this.setState({
      userOnboardingType: "",
    });
  };

  render() {
    const {
      direction,
      userOnboardingType,
      selectedOption,
      mobileNumberState,
      mobileNumber,
      emailIdState,
      emailId,
      snackBarMessage,
      showSnackBar,
      otp,
      otpType,
      contactType,
      userId,
      snackBarColor,
      showSnackBarSuccess,
      snackBarSuccessMessage,
    } = this.state;
    return (
      <React.Fragment>
        {!userOnboardingType && (
          <Register
            selectedOption={selectedOption}
            mobileNumberState={mobileNumberState}
            mobileNumber={mobileNumber}
            emailIdState={emailIdState}
            emailId={emailId}
            snackBarMessage={snackBarMessage}
            showSnackBar={showSnackBar}
            handleCountryInput={this.handleCountryInput}
            handleKeyDown={this.handleKeyDown}
            handleKeyPress={this.handleKeyPress}
            hideSnackBar={this.hideSnackBar}
            checkUserAndSendOtp={this.checkUserAndSendOtp}
            change={this.change}
            options={options}
            direction={direction}
            customStyle={customStyles}
            snackBarColor={snackBarColor}
            formatText={this.formatText}
          />
        )}
        {userOnboardingType === "LOGIN" && (
          <Otp
            otp={otp}
            otpType={otpType}
            contactType={contactType}
            userId={userId}
            onOtpChange={this.onOtpChange}
            verifyOtp={this.verifyOtp}
            resendOtp={this.resendOtp}
            snackBarMessage={snackBarMessage}
            showSnackBar={showSnackBar}
            goBack={this.goBack}
            direction={direction}
            changeMobileNumber={this.changeMobileNumber}
            hideSnackBar={this.hideSnackBar}
            showSnackBarSuccess={showSnackBarSuccess}
            snackBarSuccessMessage={snackBarSuccessMessage}
            snackBarColor={snackBarColor}
          />
        )}
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(registerStyle)(LoginAndRegistration));
