import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const lessonColumns = [
  "lo_code",
  "contentmetadata_id_of_lesson",
  "sequence_of_lesson",
  "lesson_contents",
  "assessment_questions_contents",
  "evaluation_type"
];

function LessonUpload() {
  return <BulkUpload fileColumns={lessonColumns} sheetName="lesson-data-new" />;
}

export default LessonUpload;
