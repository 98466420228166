import { cardTitle } from "../../assets/js/sms";
import buttonGroupStyle from "../../assets/js/sms/buttonGroupStyle";
import buttonStyle from "../CustomButtons/buttonStyle";
import modalStyle from "../../assets/js/sms/modalStyle";
import checkBoxStyle from "../../assets/js/sms/customCheckboxRadioSwitch";
import customSelectStyle from "../../assets/js/sms/customSelectStyle";

const lang = localStorage.getItem("language");

const schoolStyle = theme => ({
  ...buttonGroupStyle,
  ...buttonStyle,
  ...modalStyle(theme),
  ...checkBoxStyle,
  ...customSelectStyle,
  right: {
    textAlign: "right"
  },
  btns: {
    textAlign: lang == "en" ? "right" : "left !important"
  },
  tableHead: {
    textAlign: lang == "en" ? "right !important" : "left !important"
  },
  center: {
    textAlign: "center"
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    color: "#000 !important",
    fontFamily: "Dubai-bold",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    },
    fontWeight: "bold"
  },
  viewLink: {
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 1)",
    display: "inline-block",
    marginRight: "15px"
  },
  actionButton: {
    margin: "0 10px 0 10px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  addButton: {
    border: "2px solid rgba(108, 200, 6, 1)"
  },
  addIcon: {
    color: "rgba(108, 200, 6, 1)"
  },
  multiDelete: {
    padding: "12px",
    margin: "0 10px 0 0"
  },
  commonBtn: {
    position: "fixed",
    right: "50px",
    top: "150px"
  },
  modalTitle: {
    textAlign: "center !important",
    fontFamily: "Dubai-bold"
  },
  modalBody: {
    textAlign: "center !important",
    padding: "40px",
    paddingTop: "0"
  },
  submitGrades: {
    marginTop: "30px"
  },
  modalCloseButton: {
    backgroundColor: "#ef5761",
    borderRadius: "50px",
    float: "left",
    left: "0",
    marginLeft: "-40px",
    marginTop: "-42px"
  },
  finishBtn: {
    padding: "12px 60px",
    marginLeft: "30px"
  },
  minHeightCheck: {
    textAlign: "left",
    width: "95%",
    height: "400px"
  },
  searchField: {
    width: "95%"
  },
  submitButton: {
    zIndex: "1000"
  },
  classListTable: {
    height: "200px"
  },
  customCellStyle: {
    border: "0",
    paddingTop: "5px"
  },
  classDetails: {
    textAlign: "left",
    fontSize: "1.5em"
  },
  itemName: {
    fontSize: "1.2em"
  },
  selectFormControl: {
    "& > label": {
      right: lang == "ar" ? "15px !important" : "initial !important",
      left: lang == "ar" ? "initial !important" : "0 !important",
      fontFamily: "Dubai-regular"
    },
    "& > div > label": {
      right: lang == "ar" ? "0 !important" : "initial !important",
      left: lang == "ar" ? "initial !important" : "0 !important",
      fontFamily: "Dubai-regular"
    },
    "& > div > div > input": {
      textAlign: lang == "ar" ? "right" : "left",
      fontFamily: "Dubai-regular"
    },
    "& > div > div > div": {
      textAlign: lang == "ar" ? "right" : "left"
    },
    "& > div > div > svg": {
      left: lang == "ar" ? "0 !important" : "initial !important"
    }
  },
  ddlCountryCode: {
    //...customSelectStyle.selectFormControl,
    // marginTop: "20px",
  },
  mt5: {
    marginTop: "7px"
  },
  countryCode: {
    marginTop: "23px"
  },
  reactSelectContainer: {
    marginTop: "20px",
    "& > div:first-child": {
      border: "0px",
      borderBottom: "1px solid #AAAAAA",
      borderRadius: "0px",
      fontSize: "1.2em"
    }
  },
  teacherTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    textAlign: "center",
    color: "#9B9B9B",
    margin: "10px 0px"
  },
  fullWidth: {
    width: "85% !important"
  },
  // reactSelectContainer:{
  //   width: "100%"
  // },
  width50: {
    width: "50%"
  },
  cardIcon: {
    background: "linear-gradient(60deg, #66c6ec, #3bb4e5)",
    boxShadow:
      "0 14px 26px -12px rgba(98, 195, 238, 0.5), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)",
    float: lang == "en" ? "left" : "right !important",
    marginLeft: lang == "en" ? "0" : "15px"
  },
  mainCard: {
    marginTop: "60px"
  },
  primaryButton: {
    backgroundColor: "rgba(98, 195, 238, 1)",
    margin: "0 10px",
    fontFamily: "Dubai-regular",
    fontSize: "13px",
    "&:hover, &:focus": {
      backgroundColor: "rgba(98, 195, 238, 1)",
      boxShadow:
        "0 14px 26px -12px rgba(98, 195, 238, 0.5), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)"
    }
  },
  saveBtn: {
    "& > span": {
      fontFamily: "Dubai-regular"
    }
  }
});

export default schoolStyle;
