import React, { useState } from "react";

// core components
import GridContainer from "components/Grid/GridContainer/GridContainer";
import GridItem from "components/Grid/GridItem/GridItem";
import Table from "components/Table/Table";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import contentStyle from "../contentStyle";
import { useEffect } from "react";
import usePrevious from "../../../hooks/usePrevious";

function DataDisplay({ ...props }) {
  const {
    tableHead,
    tableData,
    classes,
    colspan,
    customClassesForCells,
    customHeadClassesForCells,
    language,
    initiatePage,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 50]);
  const prevTableData = usePrevious(tableData.map((row) => row[0]));
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };
  useEffect(() => {
    if (initiatePage === 0) setPage(0);
    // if (tableData.length / rowsPerPage < page) setPage(0);
  }, [initiatePage]);

  return (
    <GridContainer className={classes.grid}>
      <GridItem xs={12}>
        <Table
          pagination
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          labelRowsPerPage="عدد الاسطر في الصفحة"
          tableHead={tableHead}
          tableData={tableData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          colspan={colspan}
          customCellClasses={[classes.tableHead]}
          customClassesForCells={customClassesForCells}
          customHeadCellClasses={[classes.tableHead]}
          customHeadClassesForCells={customHeadClassesForCells}
        />
      </GridItem>
    </GridContainer>
  );
}

export default injectIntl(withStyles(contentStyle)(DataDisplay));
