import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "../../components/Header/Secure";

import secureRoutes from "../../routes/secure";

import rtlStyle from "./rtlStyle";
import layoutsStyle from "./layoutsStyle";

const switchRoutes = (
  <Switch>
    {secureRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

const rtl = true; //localStorage.getItem("direction") === "rtl" ? true : false;

class Layouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      // direction: localStorage.getItem("direction"),
      // language: localStorage.getItem("language"),
      direction: "rtl",
      language: "ar",
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.onVisibilitychange = this.onVisibilitychange.bind(this);
  }

  componentDidMount() {
    if (
      !localStorage.getItem("abjadToken") ||
      localStorage.getItem("abjadToken") === "undefined"
    ) {
      this.props.history.replace("/");
    }
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    //   ps.isRtl = false;
    //   document.body.style.overflow = "hidden";
    // }
    window.addEventListener("resize", this.resizeFunction);
    //window.addEventListener("focus", this.onFocus)
    document.addEventListener("visibilitychange", this.onVisibilitychange);
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
    window.removeEventListener("resize", this.resizeFunction);
    //window.removeEventListener("focus", this.onFocus)
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  onVisibilitychange = () => {
    if (!localStorage.getItem("abjadToken")) this.props.history.replace("/");
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  switchLanguage(params) {
    this.setState({
      language: params,
    });
    localStorage.setItem("language", params);
    window.location.reload();
  }

  switchDirection(dir) {
    this.setState({
      direction: "rtl",
    });
    localStorage.setItem("direction", "rtl");
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div className={classes.wrapper} dir="rtl">
        <div className={mainPanel} ref="mainPanel">
          <Header
            callback={this.switchLanguage.bind(this)}
            directionCallback={this.switchDirection.bind(this)}
            language="ar"
            direction="rtl"
            rtlActive={rtl === true ? true : false}
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={secureRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {/* {this.getRoute() ? <Footer rtlActive={rtl === true ? true : false} fluid /> : null} */}
        </div>
      </div>
    );
  }
}

Layouts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(rtl === true ? rtlStyle : layoutsStyle)(Layouts);
