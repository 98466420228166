import React, { useEffect } from "react";

// core components
import GridContainer from "components/Grid/GridContainer/GridContainer";
import GridItem from "components/Grid/GridItem/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { OpenBook } from "./svgIcons";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";

import contentStyle from "./contentStyle";
import DataTable from "./DataTable/DataTable";

function DataDisplay({ ...props }) {
  const {
    cardIconTitle,
    search,
    classes,
    uploadButtonText,
    uploadPath,
    searchPlaceHolderText,
    isLoading,
    sweetAlert,
    handleOnclick,
    buttonLabel
  } = props;
  const history = useHistory();

  let direction = "";
  let language = "";
  useEffect(() => {
    direction = localStorage.getItem("direction");
    language = localStorage.getItem("language");
  }, [direction, language]);

  return (
    <React.Fragment>
      <Loader open={isLoading} />
      {sweetAlert}
      <GridContainer className={classes.grid}>
        <GridItem xs={12}>
          <Card className={classes.mainCard}>
            <CardHeader color="warning" icon>
              <CardIcon color="warning" className={classes.cardIcon}>
                <OpenBook />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{cardIconTitle}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className={classes.grid}>
                <GridItem xs={12} className={classes.btns}>
                  {searchPlaceHolderText && (
                    <CustomInput
                      labelText={searchPlaceHolderText}
                      inputProps={{
                        type: "text",
                        name: "search",
                        dir: direction,
                        onChange: event => search(event)
                      }}
                    />
                  )}

                  {buttonLabel && (
                    <Button
                      color="warning"
                      className={classes.primaryButton}
                      onClick={handleOnclick}
                    >
                      {buttonLabel}
                    </Button>
                  )}
                  {uploadPath && (
                    <Button
                      color="warning"
                      className={classes.primaryButton}
                      onClick={() => history.push(uploadPath)}
                    >
                      <CloudUpload className={classes.icons} />
                      {uploadButtonText}
                    </Button>
                  )}
                </GridItem>
              </GridContainer>
              <DataTable {...props} language={language} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

export default injectIntl(withStyles(contentStyle)(DataDisplay));
