import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

// react component used to create sweet alerts

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import Snackbar from "../../../components/Snackbar/Snackbar";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "./Register.json";
import registerStyle from "./registerStyle";

//images
import blueLogo from "../../../assets/img/logo_blue.svg";

const messages = defineMessages(Translations);

const Register = ({
  classes,
  mobileNumberState,
  mobileNumber,
  emailId,
  emailIdState,
  snackBarMessage,
  showSnackBar,
  handleCountryInput,
  hideSnackBar,
  change,
  checkUserAndSendOtp,
  handleKeyDown,
  handleKeyPress,
  options,
  customStyles,
  selectedOption,
  direction,
  intl: { formatMessage },
  snackBarColor,
  formatText,
}) => {
  return (
    <div className={classes.container + " " + classes.regContainer} dir="rtl">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} className={classes.noPadding}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={10}
                  style={{ textAlign: "center" }}
                >
                  <img className={classes.logoImg} src={blueLogo} alt="" />
                  <form
                    className={classes.form + " regForm"}
                    onSubmit={(event) => checkUserAndSendOtp(event)}
                  >
                    <GridContainer dir="ltr">
                      <GridItem xs={5} sm={5} md={4}>
                        <ReactSelect
                          styles={customStyles}
                          className={classes.reactSelectContainer}
                          value={selectedOption}
                          onChange={handleCountryInput}
                          options={options}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#62c3ee",
                            },
                          })}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <CustomInput
                          success={mobileNumberState === "success"}
                          error={mobileNumberState === "error"}
                          id="mobileNumber"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            type: "text",
                            name: "mobileNumber",
                            value: mobileNumber,
                            //pattern: "[0-9]*",
                            //max : 13,
                            dir: direction,
                            onKeyPress: (event) => formatText(event),
                            onChange: (event) =>
                              change(event, "mobileNumber", "mobileLength", 9),
                            // onKeyPress: event => handleKeyPress(event),
                            // onKeyDown: event => handleKeyDown(event),
                            placeholder: formatMessage(
                              messages.register.phoneNumberLabel
                            ),
                            disabled: emailId.length > 0 ? true : false,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.seperator}>
                      {formatMessage(messages.register.orLabel)}
                    </div>
                    <CustomInput
                      success={emailIdState === "success"}
                      error={emailIdState === "error"}
                      id="emailId"
                      formControlProps={{
                        fullWidth: true,
                        className:
                          classes.customFormControlClasses +
                          " " +
                          classes.emailAddress,
                      }}
                      inputProps={{
                        type: "text",
                        name: "emailId",
                        value: emailId,
                        dir: direction,
                        onChange: (event) =>
                          change(event, "emailId", "email", 1),
                        placeholder: formatMessage(
                          messages.register.emailLabel
                        ),
                        disabled: mobileNumber.length > 0 ? true : false,
                      }}
                    />
                    <div
                      className={classes.textCenter + " " + classes.btnRegister}
                    >
                      <Button
                        type="submit"
                        className={classes.btnSubmit}
                        dir={direction}
                        onClick={(event) => checkUserAndSendOtp(event)}
                      >
                        {formatMessage(messages.register.nextButtonLabel)}
                      </Button>
                      {/* <Link
                        component="button"
                        className={classes.resendLink}
                      >
                        إعادة إرسال الرمز؟
                      </Link> */}
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {/* <GridItem xs={12} sm={12} md={4} className={classes.noPadding}>
          <Card className={classes.leftLogin}>
            <CardBody>
              <div className={classes.logoSection}>
                <img className={classes.logoImg} src={blueLogo} />
                <Avatar src={bigAvatar} className={classes.bigAvatar} />
                <h2 className={classes.cardTitle}>
                  {formatMessage(messages.register.title)}
                </h2>
                <h4
                  className={classes.cardSubTitle}
                >
                  {formatMessage(messages.register.info)}
                </h4>
              </div>
            </CardBody>
          </Card>
        </GridItem> */}

        <GridItem xs={12} sm={12} md={10} className={classes.footer}>
          {formatMessage(messages.register.copyright)}
        </GridItem>
      </GridContainer>
      <Snackbar
        place="tc"
        color={snackBarColor}
        message={snackBarMessage}
        open={showSnackBar}
        closeNotification={hideSnackBar}
        style={{ direction: "rtl" }}
        close
      />
    </div>
  );
};

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  mobileNumberState: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
  emailId: PropTypes.string.isRequired,
  emailIdState: PropTypes.string.isRequired,
  snackBarMessage: PropTypes.string.isRequired,
  showSnackBar: PropTypes.bool,
  selectedOption: PropTypes.object.isRequired,
  checkUserAndSendOtp: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default injectIntl(withStyles(registerStyle)(Register));
