import React from "react";

// services
import LearningContentService from "services/LearningContentService";
import { toast } from "react-toastify";

// core components
import DataDisplay from "../../../components/DataDisplayView/DataDisplay";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  lessonEneList: [],
  lessonId: "",
  lessonContentId: "",
  lessonText: "",
  loCode: "",
  lessonContents: [],
  alert: null,
  openassignClassDialog: false,
  btnDisabled: false
};

class FreePlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      freePlayList: [],
      freePlayListWithoutFilter: [],
      page: 0,
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50]
    };
  }

  componentDidMount() {
    this.getFreePlayData();
  }

  getFreePlayData = _ => {
    this.setState({ open: false, isLoading: true });
    LearningContentService.fetchAllFreeplayData()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              freePlayList: response._entity,
              freePlayListWithoutFilter: response._entity
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState(
          {
            isLoading: false,
            fetchData: false
          },
          () => {
            toast.error(errorMessage);
          }
        );
      });
  };

  onSearch = e => {
    const { freePlayListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = freePlayListWithoutFilter.filter(
      x =>
        (x._contentId && x._contentId.toLowerCase().indexOf(value) !== -1) ||
        (x._gradeName && x._gradeName.toLowerCase().indexOf(value) !== -1)
    );
    this.setState({ freePlayList: filteredData });
  };

  render() {
    const { isLoading, freePlayList } = this.state;

    //Default List
    let tbContentList = [];
    if (typeof freePlayList !== "undefined" && freePlayList.length > 0) {
      tbContentList = freePlayList.map(item => {
        const { _id, _contentId, _gradeName, _status } = item;
        return [_id, _contentId, _gradeName, _status];
      });
    }
    return (
      <DataDisplay
        tableHead={["Id", "Content Id", "Grade Name", "Status"]}
        tableData={tbContentList}
        search={this.onSearch}
        searchPlaceHolderText="Search Free Play"
        cardIconTitle="Lessons"
        isLoading={isLoading}
        uploadButtonText="Upload FreePlayData"
        uploadPath="/freeplay/bulk-upload"
        colspan={4}
        customClassesForCells={[3]}
        customHeadClassesForCells={[3]}
      />
    );
  }
}

export default injectIntl(FreePlay);
