import React, { Fragment } from "react";

// services
import LearningObjectiveService from "services/LearningObjectiveService";

import { toast } from "react-toastify";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";
import DataDisplay from "../../../components/DataDisplayView/DataDisplay.js";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  alert: null,
  openassignClassDialog: false,
  btnDisabled: false
};

class LearningObjective extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      learningObjectiveList: [],
      learningObjectiveListWithoutFilter: [],
      page: 0,
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50]
    };
  }

  componentDidMount() {
    this.getLessonData();
  }

  getLessonData = _ => {
    this.setState({ open: false, isLoading: true });
    LearningObjectiveService.getLearningObjective()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              learningObjectiveList: response._entity,
              learningObjectiveListWithoutFilter: response._entity
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState(
          {
            isLoading: false,
            fetchData: false
          },
          () => {
            toast.error(errorMessage);
          }
        );
      });
  };

  onSearch = e => {
    const { learningObjectiveListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = learningObjectiveListWithoutFilter.filter(
      x =>
        (x._code && x._code.toLowerCase().indexOf(value) !== -1) ||
        (x._nameAr && x._nameAr.toLowerCase().indexOf(value) !== -1) ||
        (x._axisName && x._axisName.toLowerCase().indexOf(value) !== -1) ||
        (x._topic &&
          x._topic._textAr &&
          x._topic._textAr.toLowerCase().indexOf(value) !== -1) ||
        (x._subject &&
          x._subject._nameAr &&
          x._subject._nameAr.toLowerCase().indexOf(value) !== -1)
    );
    this.setState({ learningObjectiveList: filteredData });
  };

  render() {
    const { isLoading, learningObjectiveList } = this.state;

    //Default List
    let tbContentList = [];
    if (
      typeof learningObjectiveList !== "undefined" &&
      learningObjectiveList.length > 0
    ) {
      tbContentList = learningObjectiveList.map(item => {
        const {
          _id,
          _code,
          _nameAr,
          _gradeName,
          _axisName,
          _topic,
          _subject
        } = item;
        return [
          _id,
          _code,
          _nameAr,
          _gradeName,
          _axisName,
          _topic && _topic._textAr,
          _subject && _subject._nameAr
        ];
      });
    }

    return (
      <DataDisplay
        tableHead={["Id", "Code", "Name", "Grade", "Axis", "Topic", "Subject"]}
        tableData={tbContentList}
        search={this.onSearch}
        searchPlaceHolderText="Search Content"
        cardIconTitle="المدارس"
        isLoading={isLoading}
        uploadButtonText="Upload Learning Objective"
        uploadPath="/learning-objectives/bulk-upload"
        colspan={7}
        customClassesForCells={[6]}
        customHeadClassesForCells={[6]}
      />
    );
  }
}

export default injectIntl(LearningObjective);
