import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

export function DownIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 19">
      <path
        d="M7.255 10.36l6.19-9.354a.336.336 0 00.02-.333A.308.308 0 0013.19.5H.81a.308.308 0 00-.275.173.336.336 0 00.02.333l6.19 9.355c.058.087.153.139.255.139a.307.307 0 00.255-.14z"
        fill="#62C3EE"
        fillRule="nonzero"
        transform="translate(0, +4)"
      />
    </SvgIcon>
  );
}
