import API from './api';

class LearningContentService {
  getLearningContent = async _ => {
    return API
      .get('/cms/contentmetadata')
      .then(response => response.data)
      .catch(error => error.response)
  };
  addLearningContent = async (data) => {
    return API
      .post('/cms/contentmetadata', data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  updateLearningContent = async (data) => {
    return API
      .put('/cms/contentmetadata', data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  deleteLearningContent = async (learningContentId) => {
    return API
      .delete(`/cms/contentmetadata/${learningContentId}`)
      .then(response => response.data)
      .catch(error => error.response)
  };
  addContentMapping = async (data) => {
    return API
      .post(`${process.env.REACT_APP_CONTENT_MAPPING_URL}/content/mapping`, data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  getContentMapping = async (learningObjectiveCode) => {
    return API
      .get(`${process.env.REACT_APP_CONTENT_MAPPING_URL}/${learningObjectiveCode}/contentmetadata`)
      .then(response => response.data)
      .catch(error => error.response)
  };
  clearContentMetaDataCache = async () => {
    return API
      .post('cache/evict?name=contentmetadat&evictAllKeys=true')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllLessonData = async () => {
    return API
      .get('cms/lesson')
      .then(response => response.data)
      .catch(error => error.response)
  };
  fetchAllLessonEneData = async () => {
    return API
      .get('cms/lessons-ene')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllgetGuidedReadingBookData = async () => {
    return API
      .get('cms/gr-book')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllGRTagData = async () => {
    return API
      .get('cms/gr-tag')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllFreeplayData = async () => {
    return API
      .get('cms/freeplay')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllBaselineData = async () => {
    return API
      .get('cms/baseline')
      .then(response => response.data)
      .catch(error => error.response)
  }
  fetchAllBaselineOfflineData = async () => {
    return API
      .get('cms/baseline-offline')
      .then(response => response.data)
      .catch(error => error.response)
  }
}

export default new LearningContentService();