import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";

// @material-ui/icons

import snackbarContentStyle from "./snackbarSuccessContentStyle";
import closeIcon from "../../assets/img/ic_close.svg";
import successIcon from "../../assets/img/ic_correct.svg";

function SnackbarSuccess({ ...props }) {
  const { classes, message, color, close, icon, place, open } = props;
  const language = localStorage.getItem("language");
  var action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <img
        alt=""
        src={closeIcon}
        key="close"
        aria-label="Close"
        className={classes.closeButton}
        style={language === "ar" ? { left: "0" } : { right: "0" }}
        onClick={() => props.closeNotification()}
      />,
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose",
  });
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
      }}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
            ? "center"
            : "right",
      }}
      open={open}
      message={
        <div>
          <div className={classes.errorAlert}>
            <img src={successIcon} alt="" />
            {language === "ar" ? "تمت بنجاح" : "Success"}
          </div>
          <div className={classes.errorMessage}>
            {icon !== undefined ? <props.icon className={iconClasses} /> : null}
            <span className={messageClasses}>{message}</span>
          </div>
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message,
        },
      }}
    />
  );
}

SnackbarSuccess.defaultProps = {
  color: "info",
};

SnackbarSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  // color: PropTypes.oneOf([
  //     "info",
  //     "success",
  //     "warning",
  //     "danger",
  //     "primary",
  //     "rose",

  // ]),
  close: PropTypes.bool,
  icon: PropTypes.func,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
};

export default withStyles(snackbarContentStyle)(SnackbarSuccess);
