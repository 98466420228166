import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";

// @material-ui/icons

// core components
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

import headerStyle from "./headerStyle";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      language: "ar",
      direction: "rtl",
      // language: localStorage.getItem("language") ? localStorage.getItem("language") : "ar",
      // direction: localStorage.getItem("direction") ? localStorage.getItem("direction") : "rtl"
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  getContentLanguage = (event) => {
    this.props.callback(event.target.value);
    let direction = event.target.value === "ar" ? "rtl" : "ltr";
    this.props.directionCallback(direction);
  };
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color,
    });
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <GridContainer className={classes.strap} dir="ltr">
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.firstStrap}
          ></GridItem>
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.secondStrap}
          ></GridItem>
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.thirdStrap}
          ></GridItem>
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.fourthStrap}
          ></GridItem>
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.fifthStrap}
          ></GridItem>
          <GridItem
            xs={2}
            sm={2}
            md={2}
            lg={2}
            className={classes.sixthStrap}
          ></GridItem>
        </GridContainer>
        {/* <Toolbar className={classes.container}>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar> */}
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(headerStyle)(Header);
