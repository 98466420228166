import React, { useContext, useState } from "react";
import PopUpHeader from "./PopUpHeader";
import { makeStyles } from "@material-ui/core";
import Content from "./Content";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles({
  modal: {
    width: "700px",
    position: "absolute",
    left: 0,
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    margin: "auto",
    direction: "rtl",
  },
});

const AddSchool = ({ open, onClose, schoolData, callingCodes, action }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.modal}>
        <PopUpHeader onClose={onClose} />
        <Content
          action={action}
          onClose={onClose}
          schoolData={schoolData}
          callingCodes={callingCodes}
        />
      </div>
    </Modal>
  );
};

export default AddSchool;
