import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const grBookColumns = [
  "CONTENT_ID_GR_BOOK",
  "GR_LEVEL_ID",
  "GR_BOOK_TEXT_AR",
  "GR_BOOK_TEXT_EN",
  "GR_BOOK_CONTENTS",
  "TAG_IDS"
];

function GuidedReadingUpload({}) {
  return <BulkUpload fileColumns={grBookColumns} sheetName="guided-reading-data" />;
}

export default GuidedReadingUpload;
