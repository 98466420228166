import API from "./api";

export const uploadContentMetaData = async (data) => {
  return await API.post("cms/bulk-upload/content", data);
};
export const uploadLessonData = async (data) => {
  return await API.post("cms/bulk-upload/lesson-new", data);
};
export const uploadLearningObjectiveData = async (data) => {
  return await API.post("cms/bulk-upload/lo-new", data);
};
export const uploadLessonEneData = async (data) => {
  return await API.post("cms/bulk-upload/ene-data", data);
};
export const uploadGuidedReadingBookData = async (data) => {
  return await API.post("cms/bulk-upload/guided-reading-book", data);
}
export const uploadGRTagData = async (data) => {
  return await API.post("cms/bulk-upload/gr-tag", data);
}
export const uploadFreePlayData = async (data) => {
  return await API.post("cms/bulk-upload/freePlay", data);
};
export const uploadBaselineData = async (data) => {
  return await API.post("cms/bulk-upload/ba", data);
};
export const uploadBaselineOfflineData = async (data) => {
  return await API.post("cms/bulk-upload/offline-ba", data);
};
export const clearContentMetaDataCache = async () => {
  return await API.post("cache/evict?name=contentmetadat&evictAllKeys=true")
}

export default {
  uploadLessonData,
  uploadBaselineData,
  uploadLessonEneData,
  uploadContentMetaData,
  clearContentMetaDataCache,
  uploadBaselineOfflineData,
  uploadLearningObjectiveData
};
