import React from "react";

// services
import LearningContentService from "services/LearningContentService";

import { toast } from "react-toastify";

// core components
import DataDisplay from "../../../components/DataDisplayView/DataDisplay";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  lessonEneList: [],
  lessonId: "",
  lessonContentId: "",
  lessonText: "",
  loCode: "",
  lessonContents: [],
  alert: null,
  showSnackBar: false,
  snackBarColor: "",
  snackBarMessage: "",
  openassignClassDialog: false,
  btnDisabled: false
};

class Ene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      lessonEneList: [],
      lessonEneListWithoutFilter: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50]
    };
  }

  componentDidMount() {
    this.getLessonEneData();
  }

  getLessonEneData = _ => {
    this.setState({ open: false, isLoading: true });
    LearningContentService.fetchAllLessonEneData()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              lessonEneList: response._entity,
              lessonEneListWithoutFilter: response._entity
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState(
          {
            isLoading: false,
            fetchData: false
          },
          () => {
            toast.error(errorMessage);
          }
        );
      });
  };

  onSearch = e => {
    const { lessonEneListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = lessonEneListWithoutFilter.filter(
      x => x._lessonId && x._lessonId.toLowerCase().indexOf(value) !== -1
    );
    this.setState({ lessonEneList: filteredData });
  };

  render() {
    const { isLoading, lessonEneList } = this.state;

    //Default List
    let tbContentList = [];
    if (typeof lessonEneList !== "undefined" && lessonEneList.length > 0) {
      tbContentList = lessonEneList.map(item => {
        const { _eneId, _lessonId, _eneContentResDtoWithLevels } = item;
        return [
          _eneId,
          _lessonId,
          <div>
            {_eneContentResDtoWithLevels &&
              _eneContentResDtoWithLevels[0]._contentMetaDataIds.map(item => {
                return <p>{item}</p>;
              })}
          </div>,
          <div>
            {_eneContentResDtoWithLevels &&
              _eneContentResDtoWithLevels[1]._contentMetaDataIds.map(item => {
                return <p>{item}</p>;
              })}
          </div>,
          <div>
            {_eneContentResDtoWithLevels &&
              _eneContentResDtoWithLevels[2]._contentMetaDataIds.map(item => {
                return <p>{item}</p>;
              })}
          </div>
        ];
      });
    }

    return (
      <DataDisplay
        tableHead={[
          "Ene Id",
          "Lesson Id",
          "ENH1 Contents",
          "ENR1 Contents",
          "ENR2 Contents"
        ]}
        tableData={tbContentList}
        search={this.onSearch}
        isLoading={isLoading}
        cardIconTitle="Lessons"
        searchPlaceHolderText="Search Ene Content"
        uploadButtonText="Upload Ene Data"
        uploadPath="/ene/bulk-upload"
        colspan={5}
        customClassesForCells={[4]}
        customHeadClassesForCells={[4]}
      />
    );
  }
}

export default injectIntl(Ene);
