import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const contentMetadataColumns = [
  "content_id",
  "content_type",
  "content_name",
  "content_provider_id",
  "thumb_url",
  "url",
  "stream_url",
  "category",
  "edu_concept"
];

function UploadComp({}) {
  return (
    <BulkUpload
      fileColumns={contentMetadataColumns}
      sheetName="content-meta-data"
    />
  );
}

export default UploadComp;
