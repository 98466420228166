import Schools from "../../views/Secure/Schools/Schools";
import ContentMetaData from "../../views/Secure/ContentMetaData/ContentMetaData";
import ContentMetaDataBulkUpload from "../../views/Secure/ContentMetaData/BulkUpload/ContentMetadata";
import Lesson from "views/Secure/Lesson/Lesson";
import LessonUpload from "views/Secure/Lesson/BulkUpload/LessonUpload";
import LearningObjective from "views/Secure/LearningObjective/LearningObjective";
import LearningObjectiveUpload from "views/Secure/LearningObjective/BulkUpload/LearningObjectiveUpload";
import Ene from "views/Secure/Ene/Ene";
import GuidedReading from "views/Secure/GuidedReading/GuidedReading";
import GuidedReadingUpload from "views/Secure/GuidedReading/BulkUpload/GuidedReadingUpload";
import GRTag from "views/Secure/GRTag/GRTag";
import GRTagUpload from "views/Secure/GRTag/BulkUpload/GRTagUpload";
import EneUpload from "views/Secure/Ene/BulkUpload/EneUpload";
import FreePlay from "views/Secure/FreePlay/FreePlay";
import FreePlayUpload from "views/Secure/FreePlay/BulkUpload/FreePlayUpload";
import Baseline from "views/Secure/Baseline/Baseline";
import BaselineUpload from "views/Secure/Baseline/BulkUpload/BaselineUpload";
import BaselineOffline from "views/Secure/BaselineOffline/BaselineOffline";
import BaselineOfflineUpload from "views/Secure/BaselineOffline/BulkUpload/BaselineOfflineUpload";

var routes = [
  {
    path: "/schools",
    name: "Schools",
    component: Schools,
    hederMenu: true,
    roles: [6]
  },
  {
    path: "/content-metadata/bulk-upload",
    name: "Content Metadata",
    component: ContentMetaDataBulkUpload,
    roles: [6, 5]
  },
  {
    path: "/content-metadata",
    name: "Content Metadata",
    component: ContentMetaData,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/lessons/bulk-upload",
    name: "Lessons",
    component: LessonUpload,
    roles: [6, 5]
  },
  {
    path: "/lessons",
    name: "Lessons",
    component: Lesson,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/learning-objectives/bulk-upload",
    name: "Learning Ojectives",
    component: LearningObjectiveUpload,
    roles: [6, 5]
  },
  {
    path: "/learning-objectives",
    name: "Learning Objectives",
    component: LearningObjective,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/ene/bulk-upload",
    name: "Ene",
    component: EneUpload,
    roles: [6, 5]
  },
  {
    path: "/ene",
    name: "Ene",
    component: Ene,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/guided-reading/bulk-upload",
    name: "Guided Reading",
    component: GuidedReadingUpload,
    roles: [6, 5]
  },
  {
    path: "/guided-reading",
    name: "Guided Reading",
    component: GuidedReading,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/gr-tag/bulk-upload",
    name: "GR Tag",
    component: GRTagUpload,
    roles: [6, 5]
  },
  {
    path: "/gr-tag",
    name: "GR Tag",
    component: GRTag,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/freeplay/bulk-upload",
    name: "FreePlay",
    component: FreePlayUpload,
    roles: [6, 5]
  },
  {
    path: "/freeplay",
    name: "Free Play",
    component: FreePlay,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/baseline/bulk-upload",
    name: "Baseline",
    component: BaselineUpload,
    roles: [6, 5]
  },
  {
    path: "/baseline",
    name: "Baseline",
    component: Baseline,
    dropDownMenu: true,
    roles: [6, 5]
  },
  {
    path: "/baseline-offline/bulk-upload",
    name: "Baseline Offline",
    component: BaselineOfflineUpload,
    roles: [6, 5]
  },
  {
    path: "/baseline-offline",
    name: "Baseline Offline",
    component: BaselineOffline,
    dropDownMenu: true,
    roles: [6, 5]
  }
];
export default routes;
