import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const grTagColumns = [
  "TAG_NAME_AR",
  "TAG_NAME_EN",
  "TAG_CODE"
];

function GRTagUpload({}) {
  return <BulkUpload fileColumns={grTagColumns} sheetName="guided-reading-tag" />;
}

export default GRTagUpload;
