import Public from "../layouts/Public";
import Secure from "../layouts/Secure";

var indexRoutes = [
  { path: "/schools", name: "Schools", component: Secure },
  { path: "/content-metadata", name: "ContentMetaData", component: Secure },
  { path: "/lessons", name: "Lesson", component: Secure },
  { path: "/ene", name: "Ene", component: Secure },
  { path: "/guided-reading", name: "Guided Reading", component: Secure },
  { path: "/gr-tag", name: "GR Tag", component: Secure },
  { path: "/learning-objectives", name: "LearningObjective", component: Secure },
  { path: "/freeplay", name: "FreePlay", component: Secure },
  { path: "/baseline", name: "Baseline", component: Secure },
  { path: "/baseline-offline", name: "BaselineOffline", component: Secure },
  { path: "/", name: "LoginAndRegistration", component: Public },
];

export default indexRoutes;
