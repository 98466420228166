import React from "react";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import Snackbar from "../../../components/Snackbar/Snackbar";
import SnackBarSuccess from "../../../components/SnackbarSuccess/SnackbarSuccess";
import Link from "@material-ui/core/Link";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "./Otp.json";

// css
import otpStyle from "./otpStyle";

// image
import blueLogo from "../../../assets/img/logo_blue.svg";

const messages = defineMessages(Translations);

class Otp extends React.Component {
  render() {
    const {
      classes,
      showSnackBar,
      snackBarMessage,
      snackBarColor,
      onOtpChange,
      verifyOtp,
      resendOtp,
      hideSnackBar,
      goBack,
      direction,
      intl: { formatMessage },
      snackBarSuccessMessage,
      showSnackBarSuccess,
    } = this.props;
    return (
      <div className={classes.container + " " + classes.regContainer} dir="rtl">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6} className={classes.noPadding}>
            <Card className={classes.cardSignup}>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    style={{ textAlign: "center" }}
                  >
                    <Link
                      component="button"
                      className={classes.goBackLink}
                      onClick={() => goBack()}
                    >
                      عودة
                    </Link>
                    <img className={classes.logoImg} src={blueLogo} alt="" />
                    <h2 className={classes.cardTitle}>
                      {formatMessage(messages.otp.title)}
                    </h2>
                    <h4 className={classes.cardSubTitle}>
                      {localStorage.getItem("mobileNumber") !== null
                        ? localStorage.getItem("countryCode") +
                          localStorage.getItem("mobileNumber")
                        : localStorage.getItem("email")}
                      &nbsp;&nbsp;&nbsp;
                    </h4>
                    <div className={classes.cardSubTitle + " " + classes.info2}>
                      {formatMessage(messages.otp.info2)}
                    </div>
                    <form
                      className={classes.form}
                      onSubmit={(event) => verifyOtp(event)}
                    >
                      <CardBody style={{ marginTop: "40px" }}>
                        <GridContainer justify="center" dir="ltr">
                          <GridItem xs={12} sm={12} md={9}>
                            <OtpInput
                              onChange={onOtpChange}
                              numInputs={4}
                              isInputNum={true}
                              shouldAutoFocus="true"
                              containerStyle={classes.otpcontainer}
                              disabled
                              required
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <div
                        className={
                          classes.textCenter + " " + classes.btnRegister
                        }
                      >
                        <Button
                          type="submit"
                          className={classes.btnSubmit}
                          dir={direction}
                          onClick={(event) => verifyOtp(event)}
                        >
                          {formatMessage(messages.otp.finishLabel)}
                        </Button>
                      </div>
                    </form>
                    <div
                      className={
                        classes.cardSubTitle + " " + classes.resendLink
                      }
                      onClick={() => resendOtp()}
                    >
                      {formatMessage(messages.otp.resendCodeText)}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          {/* <GridItem xs={12} sm={12} md={4} className={classes.noPadding}>
            <Card className={classes.leftLogin}>
              <CardBody style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.logoSection}>
                  <img className={classes.logoImg} src={whiteLogo} />
                  <Avatar src={bigAvatar} className={classes.bigAvatar} />
                  <h2 className={classes.cardTitle}>
                    {formatMessage(messages.otp.title2)}
                  </h2>
                  <h4
                    className={classes.cardSubTitleWhite}
                  >
                    {formatMessage(messages.otp.info3)}
                  </h4>
                </div>
              </CardBody>
            </Card>
          </GridItem> */}

          <GridItem xs={12} sm={12} md={10} className={classes.footer}>
            {formatMessage(messages.otp.copyright)}
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color={snackBarColor}
          message={snackBarMessage}
          open={showSnackBar}
          closeNotification={hideSnackBar}
          style={{ direction: "rtl" }}
          close
        />
        <SnackBarSuccess
          place="tc"
          color={snackBarColor}
          message={snackBarSuccessMessage}
          open={showSnackBarSuccess}
          closeNotification={hideSnackBar}
          style={{ direction: "rtl" }}
          close
        />
      </div>
    );
  }
}

Otp.propTypes = {
  classes: PropTypes.object.isRequired,
  snackBarMessage: PropTypes.string.isRequired,
};

export default injectIntl(withStyles(otpStyle)(Otp));
