import React from "react";

// services
import LearningContentService from "services/LearningContentService";

import { toast } from "react-toastify";

// material-ui components
import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";
import DataDisplay from "../../../components/DataDisplayView/DataDisplay.js";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  lessonList: [],
  lessonId: "",
  lessonContentId: "",
  lessonText: "",
  loCode: "",
  lessonContents: [],
  alert: null,
  openassignClassDialog: false,
  btnDisabled: false
};

class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      lessonList: [],
      lessonListWithoutFilter: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50]
    };
  }

  componentDidMount() {
    this.getLessonData();
  }

  getLessonData = _ => {
    this.setState({ open: false, isLoading: true });
    LearningContentService.fetchAllLessonData()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              lessonList: response._entity,
              lessonListWithoutFilter: response._entity
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState(
          {
            isLoading: false,
            fetchData: false
          },
          () => {
            toast.error(errorMessage);
          }
        );
      });
  };

  onSearch = e => {
    const { lessonListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = lessonListWithoutFilter.filter(
      x =>
        (x._textAr && x._textAr.toLowerCase().indexOf(value) !== -1) ||
        (x._lessonContentMetaDataId &&
          x._lessonContentMetaDataId.toLowerCase().indexOf(value) !== -1) ||
        (x._loCode && x._loCode.toLowerCase().indexOf(value) !== -1)
    );
    this.setState({ lessonList: filteredData });
  };

  render() {
    const { isLoading, lessonList } = this.state;

    //Default List
    let tbContentList = [];
    if (typeof lessonList !== "undefined" && lessonList.length > 0) {
      tbContentList = lessonList.map(item => {
        const {
          _id,
          _lessonContentMetaDataId,
          _textAr,
          _loCode,
          _lessonContents
        } = item;
        return [
          _id,
          _lessonContentMetaDataId,
          _textAr,
          _loCode,
          <div>
            {_lessonContents &&
              _lessonContents.map(item => {
                return (
                  <p>
                    {item._lessonContentType} - {item._contentId}
                  </p>
                );
              })}
          </div>
        ];
      });
    }
    return (
      <DataDisplay
        tableHead={[
          "Lesson Id",
          "Lesson Content Id",
          "Lesson Name",
          "Lesson LO",
          "Lesson Contents"
        ]}
        tableData={tbContentList}
        search={this.onSearch}
        searchPlaceHolderText="Search Content"
        cardIconTitle="Lessons"
        isLoading={isLoading}
        uploadButtonText="Upload Lesson"
        uploadPath="/lessons/bulk-upload"
        colspan={5}
        customClassesForCells={[4]}
        customHeadClassesForCells={[4]}
      />
    );
  }
}

export default injectIntl(Lesson);
