import { container, cardTitle } from "../../../assets/js/sms";
import buttonGroupStyle from "../../../assets/js/sms/buttonGroupStyle";
import buttonStyle from "../../../components/CustomButtons/buttonStyle";
import modalStyle from "../../../assets/js/sms/modalStyle";

const otpStyle = (theme) => ({
  ...buttonGroupStyle,
  ...buttonStyle,
  ...modalStyle(theme),
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "0px",
    fontFamily: "dubai-regular",
    fontSize: "36px",
    color: "rgba(0, 0, 0, 1)",
  },
  cardSubTitle: {
    fontFamily: "dubai-regular",
    fontSize: "18px",
    color: "rgba(74, 144, 226, 1)",
    textAlign: "center",
  },
  cardSubTitleWhite: {
    fontFamily: "dubai-bold",
    fontSize: "18px",
    color: "#ffffff",
    textAlign: "center",
  },
  cardSignup: {
    marginTop: "0px",
    marginBottom: "0px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "8px",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.1)",
    height: "100%",
    padding: "20px 0",
    minHeight: "500px",
  },
  // cardSignup: {
  //   background: "#fff url('https://s3.ap-south-1.amazonaws.com/teacher-assets/sms-assets/colored-stripe.jpg') repeat-x"
  // },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  seperator: {
    textAlign: "center",
    marginTop: "15px",
    color: "rgba(192, 192, 192, 1)",
    fontFamily: "dubai-regular",
    fontSize: "18px",
  },
  tnc: {
    textAlign: "center",
    marginTop: "20px",
    color: "#AAAAAA",
  },
  terms: {
    textDecoration: "none",
    color: "#AAAAAA !important",
  },
  reactSelectContainer: {
    marginTop: "20px",
    fontFamily: "dubai-bold",
    fontSize: "14px",
    color: "rgba(170, 170, 170, 1)",
    "& > div:first-child": {
      border: "0px",
      borderBottom: "1px solid rgba(210, 210, 210, 1)",
      borderRadius: "0px",
      fontSize: "1.2em",
    },
    "&>div>div:first-child>div": {
      fontFamily: "dubai-bold",
      fontSize: "14px",
      color: "rgba(170, 170, 170, 1)",
    },
    "&>div>div:nth-child(2)>div": {
      color: "rgba(0, 0, 0, 1)",
    },
  },
  btnRegister: {
    marginTop: "35px",
    marginBottom: "25px",
  },
  customFormControlClasses: {
    "&>div>input": {
      fontFamily: "dubai-bold",
      fontSize: "14px",
      color: "rgba(170, 170, 170, 1)",
    },
    "&>div::before": {
      borderBottom: "1px solid rgba(210, 210, 210, 1) !important",
    },
  },
  emailAddress: {
    paddingTop: "15px",
  },
  btnSubmit: {
    backgroundColor: "rgba(110, 195, 11, 1)",
    borderRadius: "8px",
    fontFamily: "dubai-bold",
    fontSize: "20px",
    color: "rgba(255, 255, 255, 1)",
    fontWeight: "bold",
    padding: "16px 30px",
    margin: "0px",
    width: "100%",
  },
  footer: {
    fontFamily: "dubai-bold",
    fontSize: "18px",
    color: "rgba(124, 124, 124, 1)",
    textAlign: "center",
    marginTop: "60px",
  },
  otpcontainer: {
    justifyContent: "center",
    "& input": {
      width: "100% !important",
      border: "0px",
      borderBottom: "1px solid rgba(210, 210, 210, 1)",
      paddingTop: "20px",
      fontSize: "34px;",
      color: "rgba(170, 170, 170, 1)",
      fontFamily: "dubai-regular",
      textAlign: "center",
    },
    "& > div": {
      margin: "0 10px",
    },
  },
  info2: {
    color: "rgba(108, 108, 108, 1)",
    marginTop: "5px",
  },
  resendCodeText: {
    color: "rgba(74, 144, 226, 1)",
    //textDecoration : "underline",
    marginTop: "10px",
    marginBottom: "30px",
    cursor: "pointer",
    "&:hover": {
      color: "rgba(74, 144, 226, 1)",
    },
  },
  noPadding: {
    padding: "0 !important",
  },
  leftLogin: {
    margin: "0",
    height: "100%",
    background: "#62c3ee",
  },
  regContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    direction: "rtl",
  },
  logoSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px 40px",
  },
  bigAvatar: {
    margin: 10,
    width: 150,
    height: 150,
  },
  logoImg: {
    marginBottom: "40px",
    width: "210px",
  },
  resendLink: {
    marginTop: "35px",
    fontSize: "18px",
    color: "#62c3ee",
    fontFamily: "dubai-regular",
    cursor: "pointer",
  },
  goBackLink: {
    fontSize: "18px",
    color: "#62c3ee",
    fontFamily: "dubai-regular",
    position: "absolute",
    top: "0px",
    right: "37px",
  },
  schLogo: {
    maxWidth: "340px",
    display: "block",
    margin: "auto",
  },
});

export default otpStyle;
