import {
  drawerMiniWidth,
  transition,
  containerFluid
} from "../../assets/js/sms";

const appStyle = theme => ({
  wrapper: {
    direction: "rtl",
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    },
    "@media print": {
      height: "100%"
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // },
    overflow: "auto",
    position: "relative",
    float: "left",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    "@media print": {
      width: "100% !important"
    }
  },
  content: {
    // marginTop: "100px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
    "@media print": {
      marginTop: "0px",
      padding: "0px"
    }
  },
  container: { ...containerFluid },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  }
});

export default appStyle;
