import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {Route, Router, Switch} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import indexRoutes from "./routes";
import "./assets/scss/sms.css?v=1.4.0";
import { APP_VERSION } from "./constants";
import { IntlProvider, addLocaleData } from "react-intl";
import translations from "./i18n/locales";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

if (localStorage.getItem("app_version") == null) {
  localStorage.setItem("app_version", APP_VERSION);
} else {
  if (localStorage.getItem("app_version") !== APP_VERSION) {
    localStorage.clear();
    localStorage.setItem("app_version", APP_VERSION);
  }
}

const hist = createBrowserHistory();
localStorage.setItem("language", "ar");
localStorage.setItem("direction", "rtl");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(98 195 238)",
    },
    secondary: {
      main: "rgb(110 195 11)",
    },
  },
});

const messages = translations["ar"];

ReactDOM.render(
  <IntlProvider locale="ar" key="ar" messages={messages}>
    <ToastContainer position="top-center" rtl />
    <MuiThemeProvider theme={theme}>
      <Router history={hist}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </Router>
    </MuiThemeProvider>
  </IntlProvider>,
  document.getElementById("root")
);
