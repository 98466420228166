import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const greetingMessage = () => (
  <>
    <span>مرحبا </span>
    <span style={{ color: "#62c3ee", marginRight: "4px" }}>
      {localStorage.getItem("userName")}
    </span>
  </>
);

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  menu: {
    top: "125%",
    left: 0,
    color: "rgb(51 51 51)",
    position: "absolute",
    width: "max-content",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(218 218 218)",
    borderRadius: "0.75vw",
    boxShadow: " 0px 5px 10px 0px rgba(0, 0, 0, 0.06)",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "100%",
      left: "0.65vw",
      borderWidth: "0.75vw",
      borderStyle: "solid",
      borderColor: "transparent transparent  rgb(218 218 218) transparent"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "100%",
      left: "0.75vw",
      borderWidth: "0.65vw",
      borderStyle: "solid",
      borderColor: "transparent transparent  rgb(255 255 255) transparent"
    },

    " & *": {
      fontSize: "1.25vw",
      textAlign: "center"
    }
  },
  splitbtn: {
    color: "#62c3ee",
    fontSize: "2vw",
    marginRight: "-0.5vw"
  }
});

const AvatarMenu = () => {
  const [visibility, setVisibility] = useState("hidden"); //,visible
  const history = useHistory();
  const handleClick = () => {
    setVisibility(visibility === "hidden" ? "visible" : "hidden");
  };
  const handleClose = event => {
    const actionName = event.currentTarget.getAttribute("name");
    switch (actionName) {
      case "students":
        {
          setVisibility("hidden");
          history.push("/students");
        }
        break;
      case "logout":
        {
          setVisibility("hidden");
          localStorage.clear();
          localStorage.setItem("language", "ar");
          localStorage.setItem("direction", "rtl");
          history.push("/login");
        }
        break;
      case "profile":
      default:
        break;
    }
  };
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={handleClick}>
      <AccountCircleIcon
        style={{ fontSize: "3.5vw", color: "rgb(60 180 229)" }}
      />
      {visibility === "hidden" ? (
        <ExpandMoreIcon className={classes.splitbtn} />
      ) : (
        <ExpandLessIcon className={classes.splitbtn} />
      )}

      <div className={classes.menu} style={{ visibility }}>
        <List component="nav">
          <ListItem name="profile" button onClick={handleClose}>
            <ListItemText primary={greetingMessage()} />
          </ListItem>
          <Divider />

          <ListItem name="logout" button onClick={handleClose}>
            <ListItemText primary="تسجيل الخروج" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default AvatarMenu;
