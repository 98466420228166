import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  dropDownMenu: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  menu: {
    top: "85%",
    left: 0,
    color: "rgb(51 51 51)",
    position: "absolute",
    width: "max-content",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(218 218 218)",
    borderRadius: "0.75vw",
    boxShadow: " 0px 5px 10px 0px rgba(0, 0, 0, 0.06)",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "100%",
      left: "0.65vw",
      borderWidth: "0.75vw",
      borderStyle: "solid",
      borderColor: "transparent transparent  rgb(218 218 218) transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "100%",
      left: "0.75vw",
      borderWidth: "0.65vw",
      borderStyle: "solid",
      borderColor: "transparent transparent  rgb(255 255 255) transparent",
    },

    " & *": {
      fontSize: "1.25vw",
      textAlign: "center",
    },
  },
  splitbtn: {
    color: "#62c3ee",
    fontSize: "2vw",
    marginRight: "-0.5vw",
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:992px)": {
      display: "none",
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.5vw",
    fontWeight: "bold",
    color: "rgb(131 131 131)",
    "& *": {
      minWidth: "unset",
      padding: "0.25rem",
    },
    "& .active": {
      color: "rgb(255 255 255)",
      background: "rgb(60, 180, 229)",
      borderRadius: "35px",
      padding: "0px 20px",
      lineHeight: "50px",
    },
  },
});
function MobileMenu({ routes, roleId }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [visibility, setVisibility] = useState("hidden");
  const [currentPath, setCurrentPath] = React.useState({
    name: "Select Data To Upload",
    path: "",
  });

  const handleClick = () => {
    setVisibility(visibility === "hidden" ? "visible" : "hidden");
  };

  const handleClose = (event, path) => {
    setVisibility("hidden");
    history.push(path);
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const menuItems = routes.map(
    (prop, index) =>
      prop.roles &&
      prop.roles.indexOf(roleId) >= 0 &&
      prop.hederMenu && (
        <NavLink to={prop.path} key={index}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={prop.name}
              className={activeRoute(prop.path) ? "active" : null}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      )
  );

  useEffect(() => {
    routes.map((prop, key) => {
      if (prop.path === location.pathname && !prop.hederMenu) {
        setCurrentPath(prop);
        return;
      }
    });
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <List className={classes.list}>{menuItems}</List>
      <div className={classes.dropDownMenu} onClick={handleClick}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={currentPath.name}
            disableTypography={true}
            className={currentPath.path === location.pathname ? "active" : null}
          />
        </ListItem>
        {visibility === "hidden" ? (
          <ExpandMoreIcon className={classes.splitbtn} />
        ) : (
          <ExpandLessIcon className={classes.splitbtn} />
        )}

        <div className={classes.menu} style={{ visibility }}>
          <List component="nav">
            {routes.map((prop, index) => {
              return (
                prop.dropDownMenu &&
                prop.roles &&
                prop.name !== currentPath.name &&
                prop.roles.indexOf(roleId) >= 0 && (
                  <React.Fragment key={"key" + index}>
                    <ListItem
                      button
                      onClick={(event) => handleClose(event, prop.path)}
                    >
                      <ListItemText primary={prop.name} />
                    </ListItem>
                    {index !== routes.length - 1 && <Divider />}
                  </React.Fragment>
                )
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
