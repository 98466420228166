import React, { Fragment } from "react";

// services
import SchoolService from "../../../services/SchoolService";
import UserService from "../../../services/UserService";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

import Button from "../../../components/CustomButtons/Button";

// style
import schoolStyle from "./schoolStyle";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "./Schools.json";
import DataDisplay from "../../../components/DataDisplayView/DataDisplay";
import AddSchool from "./AddSchool";
import SearchSchool from "./SearchSchool";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  schoolName: "",
  schoolNameState: "success",
  city: "",
  firstName: "",
  firstNameState: "",
  lastName: "",
  lastNameState: "",
  emailIdState: "",
  emailId: "",
  principalFirstName: "",
  principalLastName: "",
  principalEmailId: "",
  principalMobileNumber: "",
  schoolPrincipalUserId: null,
  educationalYearStartDate: null,
  educationalYearEndDate: null,
  schoolPrincipalCountryCode: "",
  term1: null,
  term2: null,
  open: false,
  schoolLeaderId: "",
  isLoading: false,
  action: "",
  mobileNumber: "",
  alert: null,
  showSnackBar: false,
  snackBarColor: "",
  snackBarMessage: "",
  openassignClassDialog: false,
  searchClass: "",
  mobileNumberState: "success",
  btnDisabled: false,
  schoolList: [],
  allSchools: [],
  countryCode: "971",
  callingCodes: [],
  selectedOption: { label: "+971", value: "+971" },
  selectedSchoolTypeOption: { label: "ADEK", value: "ADEK" },
  initiatePage: -1,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50],
};

class Schools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
    };
    this.btnActionClick = this.btnActionClick.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        schoolId:
          localStorage.getItem("schoolId") &&
          parseInt(localStorage.getItem("schoolId")),
      },
      () => {
        this.getSchoolsData();
        this.setCallingCodes();
      }
    );
  }

  getSchoolsData = () => {
    this.setState({ isLoading: true });
    SchoolService.getAllSchools()
      .then((response) => {
        if (response && parseInt(response._statusCode) === 200)
          if (response._entity) {
            const list = response._entity.filter(
              (school) =>
                school._educationalYearStartDate &&
                school._educationalYearEndDate
            );
            this.setState(
              {
                schoolList: list,
              },
              () => {
                this.setState({ allSchools: list });
                this.setState({ isLoading: false });
              }
            );
          } else {
            this.setState({ schoolList: [] });
            this.setState({ allSchools: [] });
          }
        else throw response;
      })
      .then(() => this.setState({ isLoading: false }))
      .catch((e) =>
        this.setState(
          {
            isLoading: false,
          },
          () => {
            // toast.error("something went wrong");
          }
        )
      );
  };

  setCallingCodes = async () => {
    let codeList = [];
    try {
      const response = await SchoolService.getCountryCallingCodesList();
      if (response._statusCode === 200) {
        const { _entity: data } = response;
        if (data.length > 0)
          codeList = data.map((country) => country._callingCode);
        codeList.sort((a, b) => (a > b ? 1 : -1));
        codeList = [...new Set(codeList)];
        codeList = codeList.map((code) => ({
          value: code,
          label: (
            <span
              style={{
                direction: "ltr",
                display: "block",
                whiteSpace: "nowrap",
              }}
            >
              {code}
            </span>
          ),
        }));
        this.setState({ callingCodes: codeList });
      } else throw response;
    } catch (error) {
      console.log(error);
    }
  };

  btnActionClick = (action, item) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      _schoolLeaderEmail,
      _name,
      _schoolLeaderMobile,
      _schoolLeaderFirstName,
      _schoolLeaderLastName,
      _educationalYearStartDate,
      _educationalYearEndDate,
      _termOneEndDate,
      _termTwoEndDate,
      _schoolLeaderUserId,
      _schoolPrincipalEmail,
      _schoolPrincipalFirstName,
      _schoolPrincipalLastName,
      _schoolPrincipalMobile,
      _schoolPrincipalUserId,
      _schoolPrincipalCountryCode,
      _id,
      _city,
      _isActive,
    } = item;
    this.setState(
      {
        action,
        schoolId: _id,
        schoolName: _name,
        emailId: _schoolLeaderEmail ?? "",
        mobileNumber: _schoolLeaderMobile
          ? _schoolLeaderMobile.replace("+", "")
          : "",
        firstName: _schoolLeaderFirstName,
        lastName: _schoolLeaderLastName,
        schoolLeaderId: _schoolLeaderUserId,
        educationalYearStartDate: _educationalYearStartDate,
        educationalYearEndDate: _educationalYearEndDate,
        term1: _termOneEndDate || null,
        term2: _termTwoEndDate || null,
        city: _city,
        principalEmailId: _schoolPrincipalEmail ?? "",
        principalFirstName: _schoolPrincipalFirstName,
        schoolPrincipalCountryCode: _schoolPrincipalCountryCode,
        principalLastName: _schoolPrincipalLastName,
        principalMobileNumber: _schoolPrincipalMobile ?? "",
        schoolPrincipalUserId: _schoolPrincipalUserId,
      },
      () => {
        if (action === "delete") {
          const { schoolName } = this.state;
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={formatMessage(messages.teachers.sureLabel)}
                onConfirm={() => this.successDelete()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText={formatMessage(messages.teachers.deleteBtnLabel)}
                cancelBtnText={formatMessage(messages.teachers.cancelLabel)}
                showCancel
              >
                {formatMessage(messages.teachers.deleteWarningText)}{" "}
                <strong>{schoolName}</strong>
              </SweetAlert>
            ),
          });
        } else if (action === "getPin") {
          const { schoolLeaderId } = this.state;
          UserService.getTeacherPinByAbjadId(schoolLeaderId)
            .then((response) => {
              if (response && parseInt(response._statusCode) === 200)
                if (response._entity) {
                  this.setState({ currentTeacherPin: response._entity });
                } else
                  this.setState({
                    currentTeacherPin: "",
                  });
              else throw response;
            })
            .then((_) => this.setState({ isLoading: false }))
            .catch((e) => this.showErrorMessage(e));
        } else if (action === "getPrincipalPin") {
          const { schoolPrincipalUserId } = this.state;
          UserService.getTeacherPinByAbjadId(schoolPrincipalUserId)
            .then((response) => {
              if (response && parseInt(response._statusCode) === 200)
                if (response._entity) {
                  this.setState({ currentPrincipalPin: response._entity });
                } else
                  this.setState({
                    currentPrincipalPin: "",
                  });
              else throw response;
            })
            .then((_) => this.setState({ isLoading: false }))
            .catch((e) => this.showErrorMessage(e));
        } else if (action === "edit") {
          this.setState({ open: true });
        } else if (action === "startSchoolRollOver") {
          const {
            schoolId,
            educationalYearEndDate,
            educationalYearStartDate,
            schoolName,
          } = this.state;
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() =>
                  this.performSchoolCleanup(
                    schoolId,
                    educationalYearEndDate,
                    educationalYearStartDate,
                    schoolName
                  )
                }
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
              >
                Are You sure want to clean up this school. All student teacher
                mappings and progress data will be lost.
              </SweetAlert>
            ),
          });
        }
      }
    );
  };

  performSchoolCleanup = (
    schoolId,
    educationalYearEndDate,
    educationalYearStartDate,
    schoolName
  ) => {
    const data = {
      _schoolId: schoolId,
      _dbManualBackupTaken: true,
      _schoolDataReportTaken: true,
      _educationalYearEndDate: educationalYearEndDate,
      _educationalYearStartDate: educationalYearStartDate,
      _rollOverRequiredForSchool: true,
    };
    SchoolService.performSchoolRollover(data)
      .then((response) => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Done"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                <strong>{schoolName}</strong> rollOver done successfully.
              </SweetAlert>
            ),
          });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                There is some error while doing school rollOver. Please try
                after sometime.
              </SweetAlert>
            ),
          });
        }
      })
      .then((_) => {
        this.getSchoolsData();
        this.setState({ isLoading: false });
      })
      .catch((e) => this.showErrorMessage(e));
  };

  successDelete = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      schoolName,
      schoolId,
      educationalYearStartDate,
      educationalYearEndDate,
    } = this.state;
    const data = {
      _schoolId: schoolId,
      _isActive: false,
      _educationalYearStartDate: educationalYearStartDate,
      _educationalYearEndDate: educationalYearEndDate,
    };
    SchoolService.deleteSchool(data)
      .then((response) => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState({
            fetchData: true,
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                <strong>{schoolName}</strong>{" "}
                {formatMessage(messages.teachers.deleteConfirmText)}
              </SweetAlert>
            ),
          });
          this.getSchoolsData();
        } else throw response;
      })
      .then(() => this.props.onRefresh && this.props.onRefresh("classess"))
      .catch((e) => this.showErrorMessage(e));
  };

  showErrorMessage = (error) => {
    const {
      intl: { formatMessage },
    } = this.props;
    let errorMessage = "";
    if (error?._message) errorMessage = error._message;
    else errorMessage = formatMessage(messages.teachers.errorMessageText);
    this.setState(
      {
        isLoading: false,
        fetchData: false,
      },
      () => {
        // toast.error(errorMessage);
      }
    );
  };

  hideAlert = () => {
    const { fetchData } = this.state;
    this.setState(
      {
        alert: null,
      },
      () => {
        if (fetchData) {
          this.getSchoolsData();
        }
      }
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  switchRestrictedUse = (restrictedUse, schoolId, schoolName) => {
    const data = {
      _schoolId: schoolId,
      _action: restrictedUse ? "UN_RESTRICT" : "RESTRICT",
    };
    SchoolService.switchRestrictedUse(data)
      .then((response) => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState({
            fetchData: true,
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Done"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                <strong>{schoolName}</strong>{" "}
                {restrictedUse
                  ? "is now ready to use."
                  : "is now restricted for use."}
              </SweetAlert>
            ),
          });
        } else throw response;
      })
      .then(() => console.log("request complete"))
      .catch((e) => this.showErrorMessage(e));
  };

  showAddSchoolPopUp = () => {
    this.setState({
      open: true,
      action: "add",
    });
  };

  searchHandler = (type, value) => {
    let schools = this.state.allSchools;
    if (value && value.length > 0)
      schools = [...this.state.allSchools].filter((school) =>
        type === "id"
          ? school._id.toString().includes(value)
          : school._name.toLowerCase().includes(value.toLowerCase())
      );
    this.setState({ schoolList: schools, initiatePage: 0 }, () => {
      this.setState({ initiatePage: -1 });
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      alert,
      emailId,
      open,
      mobileNumber,
      schoolList,
      selectedSchoolTypeOption,
      schoolName,
      firstName,
      lastName,
      educationalYearStartDate,
      educationalYearEndDate,
      term1,
      term2,
      principalFirstName,
      principalLastName,
      principalEmailId,
      principalMobileNumber,
      schoolPrincipalUserId,
      currentTeacherPin,
      currentPrincipalPin,
      schoolLeaderId,
      schoolPrincipalCountryCode,
      callingCodes,
      action,
    } = this.state;

    //Default List
    let tbSchoolList = [];
    if (typeof schoolList !== "undefined" && schoolList.length > 0) {
      tbSchoolList = schoolList.map((item) => {
        const {
          _educationalYearEndDate,
          _schoolLogoUrl,
          _educationalYearStartDate,
          _id,
          _name,
          _schoolLeaderMobile,
          _schoolLeaderEmail,
          _schoolPrincipalMobile,
          _schoolPrincipalEmail,
          _restrictedUse,
        } = item;
        return [
          _id,
          _name,
          //_schoolLeaderFirstName + " " + _schoolLeaderLastName,
          _schoolLeaderMobile ? _schoolLeaderMobile.replace("+", "") : "",
          _schoolLeaderEmail,
          <div id="leader" style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Button
                color="transparent"
                className={
                  item._schoolLeaderUserId == schoolLeaderId
                    ? currentTeacherPin
                      ? classes.actionButton
                      : classes.actionButton + " " + classes.addButton
                    : classes.actionButton + " " + classes.addButton
                }
                //className={classes.actionButton + " " + classes.addButton}
                onClick={() => {
                  this.setState({ currentPrincipalPin: "" });
                  this.btnActionClick("getPin", item);
                }}
              >
                {item._schoolLeaderUserId == schoolLeaderId ? (
                  currentTeacherPin ? (
                    <span style={{ fontSize: "20px" }}>
                      {currentTeacherPin}
                    </span>
                  ) : (
                    "Get Pin"
                  )
                ) : (
                  " Get Pin"
                )}
              </Button>
            </div>
          </div>,
          //_schoolPrincipalFirstName + " " + _schoolPrincipalLastName,
          _schoolPrincipalMobile ? _schoolPrincipalMobile.replace("+", "") : "",
          _schoolPrincipalEmail,
          <div id="principal" style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Button
                color="transparent"
                className={
                  item._schoolPrincipalUserId == schoolPrincipalUserId
                    ? currentPrincipalPin
                      ? classes.actionButton
                      : classes.actionButton + " " + classes.addButton
                    : classes.actionButton + " " + classes.addButton
                }
                //className={classes.actionButton + " " + classes.addButton}
                onClick={() => {
                  this.setState({ currentTeacherPin: "" });
                  item._schoolPrincipalUserId &&
                    this.btnActionClick("getPrincipalPin", item);
                }}
              >
                {item._schoolPrincipalUserId == schoolPrincipalUserId ? (
                  currentPrincipalPin ? (
                    <span style={{ fontSize: "20px" }}>
                      {currentPrincipalPin}
                    </span>
                  ) : (
                    "Get Pin"
                  )
                ) : (
                  " Get Pin"
                )}
              </Button>
            </div>
          </div>,
          _educationalYearStartDate,
          _educationalYearEndDate,
          /* _schoolLogoUrl && _schoolLogoUrl.indexOf("http") > -1
            ? ""
            : _schoolLogoUrl,*/
          <Fragment>
            {_restrictedUse &&
            _educationalYearEndDate &&
            _educationalYearStartDate ? (
              <Button
                color="success"
                className={classes.actionButton}
                onClick={() => this.btnActionClick("startSchoolRollOver", item)}
              >
                Clean-Up
              </Button>
            ) : null}
          </Fragment>,
          <Switch
            checked={_restrictedUse}
            onChange={() =>
              this.switchRestrictedUse(_restrictedUse, _id, _name)
            }
          />,
          <Fragment>
            {[
              { color: "success", icon: Edit, action: "edit" },
              { color: "danger", icon: Close, action: "delete" },
            ].map((prop, key) => {
              return (
                <Button
                  color={prop.color}
                  className={classes.actionButton}
                  key={key}
                  onClick={() => this.btnActionClick(prop.action, item)}
                >
                  <prop.icon className={classes.icon} />
                </Button>
              );
            })}
          </Fragment>,
        ];
      });
    }

    return (
      <React.Fragment>
        <AddSchool
          schoolData={{
            schoolId: this.state.schoolId,
            schoolName,
            emailId,
            mobileNumber,
            firstName,
            lastName,
            schoolLeaderId,
            educationalYearStartDate,
            educationalYearEndDate,
            term1,
            term2,
            principalFirstName,
            principalLastName,
            principalEmailId,
            principalMobileNumber,
            schoolPrincipalCountryCode,
            schoolPrincipalUserId,
            selectedSchoolTypeOption,
          }}
          open={open}
          action={action}
          callingCodes={callingCodes}
          onClose={() => {
            this.setState(
              { ...INITIAL_STATE, open: false, isLoading: true, callingCodes },

              this.getSchoolsData()
            );
          }}
        />
        <SearchSchool search={this.searchHandler} />
        <DataDisplay
          tableHead={[
            "Id",
            "Name",
            //"Admin Name",
            "Admin Mobile",
            "Admin Email",
            "Admin Pin",
            "Principal Mobile",
            "Principal Email",
            "Principal Pin",
            "Start Date",
            "End Date",
            // "School Type",
            "School RollOver",
            "Restricted Use",
            "Actions",
          ]}
          initiatePage={this.state.initiatePage}
          tableData={tbSchoolList}
          cardIconTitle="المدارس"
          isLoading={isLoading}
          sweetAlert={alert}
          colspan={12}
          customClassesForCells={[11]}
          customHeadClassesForCells={[11]}
          handleOnclick={this.showAddSchoolPopUp}
          buttonLabel={
            <>
              <Add className={classes.icons} /> أضف مدرسة
            </>
          }
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(schoolStyle)(Schools));
