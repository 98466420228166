import LoginAndRegistration from "../../views/Public/LoginAndRegistration";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";

const routes = [
  {
    path: "/",
    name: "LoginAndRegistration",
    short: "profile",
    mini: "PC",
    icon: Fingerprint,
    component: LoginAndRegistration,
  },
];

export default routes;
