import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { DownIcon } from "../SVGs/index";

const useStyles = makeStyles({
  assignmentType: {
    "@media screen and (max-width: 1100px)": {
      "& div": {
        fontSize: "0.8rem !important",
      },
    },
    "& > [class*='control']": {
      padding: "0.25rem 0.75rem",
      cursor: "pointer",
    },
    "& > [class*='control']:hover": {
      boxShadow: "none !important",
    },
    "& > [class*='menu']": {
      marginTop: "0.75rem",
      overflow: "hidden",
      zIndex: "2",
      margin: 0,
      top: "90%",
    },
    "& > [class*='menu'] > div > [class*='option']": {
      color: "#636363",
      height: "2.5rem",
      display: "flex",
      alignItems: "center",
    },
    "& > [class*='menu'] > div": {
      padding: 0,
    },
    "& > div": {
      borderRadius: "0.5rem",
      fontSize: "1rem",
      padding: 0,
      overflow: "hidden",
    },
    "& > [class*='control'] > div": {
      padding: "0 !important",
      height: "2rem",
    },
    "& > [class*='menu'] > div > div": {
      fontSize: "1rem",
      textAlign: "right",
      overflow: "visible",
      padding: "0.5rem 0.75rem",
    },
    "& > [class*='control'] > div > div": {
      fontSize: "1rem",
      textAlign: "right",
      overflow: "visible",
      padding: "1rem 0",
    },
  },
});

const customStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "rgba(98, 195, 238, 1)"
        : isFocused
        ? "rgba(239, 239, 239, 1)"
        : null,
      color: isDisabled ? "#ccc" : isSelected ? "white !important" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled &&
          (isSelected ? "rgba(98, 195, 238, 1)" : "rgba(239, 239, 239, 1)"),
        color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
      },
    };
  },
  indicatorSeparator: () => ({
    display: "none !important",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownIcon style={{ padding: "0.25rem" }} />
    </components.DropdownIndicator>
  );
};

export default function SchoolSelect({
  schoolTypeOptions,
  selectedSchool,
  handleSchoolSelect,
  disabled,
}) {
  const classes = useStyles();

  return (
    <ReactSelect
      className={classes.assignmentType}
      styles={customStyles}
      components={{ DropdownIndicator }}
      isSearchable={false}
      options={schoolTypeOptions}
      onChange={handleSchoolSelect}
      value={selectedSchool}
      isDisabled={disabled}
      isRtl
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "rgba(98, 195, 238, 1)",
        },
      })}
    />
  );
}

SchoolSelect.propTypes = {
  schoolTypeOptions: PropTypes.array.isRequired,
  selectedSchool: PropTypes.object,
  handleSchoolSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SchoolSelect.defaultProps = {
  defaultDomain: {},
};
