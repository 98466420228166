import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import spreadSheetUploadIcon from "assets/img/spreadsheetUpload.svg";
import cancelUploadedFile from "assets/img/cancel.svg";
import {
  uploadLessonData,
  uploadLearningObjectiveData,
  uploadLessonEneData,
  uploadFreePlayData,
  uploadBaselineData,
  uploadBaselineOfflineData,
  uploadContentMetaData,
  uploadGuidedReadingBookData,
  uploadGRTagData
} from "services/UploadService";
import Tooltip from "@material-ui/core/Tooltip";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  uploadComp: {
    display: "flex",
    cursor: "default",
    userSelect: "none",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    width: "100%",
    backgroundColor: "rgb(247 247 247)",
    border: "1px solid rgb(224 224 224)",
    borderRadius: "15px",
    margin: "1rem 0"
  },
  download: {
    padding: "0.25rem",
    "& svg": {
      width: "27px",
      height: "27px",
      margin: "4px"
    }
  },
  downloadBtn: {
    position: "relative",
    cursor: "pointer",
    padding: "5px",
    borderRadius: "50%",
    width: "43px",
    height: "43px",
    margin: "0 0.25rem",
    "& svg": {
      width: "27px",
      height: "27px",
      margin: "4px"
    },
    "&:hover svg g": {
      fill: "#fff"
    },
    "&:hover": {
      backgroundColor: "rgb(134 189 48)"
    }
  },
  uploadIcon: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    cursor: "pointer",
    color: "rgb(255 255 255)",
    fontSize: "1rem",
    height: "2.2rem",
    width: "6rem",
    backgroundColor: "rgb(98 195 238)",
    borderRadius: "0.75rem",
    margin: "0 0.2rem",
    "&:hover": {
      backgroundColor: "rgb(134 189 48)"
    }
  },
  placeholder: {
    fontSize: "16px",
    flexGrow: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "150px",
    color: "#4a4a4a",
    cursor: "default"
  },
  upload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px",
    marginTop: "20%",
    marginBottom: "20%",
    marginRight: "40%",
    height: "42px",
    width: "275px",
    backgroundColor: "#e6e6e6",
    border: "1px solid #979797",
    borderRadius: "15px",
    textAlign: "center",
    "&:hover": {
      borderColor: "#62c3ee",
      boxShadow: "0 0 6px #62c3ee"
    }
  },
  spreadSheetErrors: {
    cursor: "pointer"
  },
  errorHoverIcon: {
    margin: "3px",
    position: "relative",
    width: "45px",
    height: "45px",
    borderRadius: "45px",
    border: "1px solid #ffe2e6",
    "&:hover": {
      boxShadow: "0 0 6px #f54f64"
    }
  }
});

function BulkUpload({ fileColumns, sheetName }) {
  const classes = useStyles();
  const [fileSelector, setFileSelector] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [binaryFile, setBinaryFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState({
    data: [],
    fileName: "Upload a file"
  });
  useEffect(() => {
    setFileSelector(buildFileSelector());
  }, []);

  const handleFileChange = async event => {
    try {
      if (event.target.files.length === 0) {
        toast.warn("You didnt select a file to upload");
        return;
      }
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const detailsSheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(
          detailsSheet
        );

        const newData = XL_row_object.map(row => {
          const newrowData = {};
          fileColumns.forEach(element => {
            newrowData[element] = row[element] ? row[element] : null;
          });

          return newrowData;
        });
        const uploadedFile = {
          data: newData,
          fileName: file.name
        };
        setUploadedFile(uploadedFile);
        setBinaryFile(file);
      };
      reader.onerror = ex => {
        setUploadedFile({ data: [], fileName: undefined });
        console.log(ex);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.log(error);
    }
  };
  const buildFileSelector = () => {
    const fileInput = document.createElement("input");
    fileInput.setAttribute("type", "file");
    fileInput.setAttribute("accept", ".xlsx");
    fileInput.onchange = handleFileChange;
    fileInput.onclick = function() {
      this.value = null;
    };
    return fileInput;
  };

  const handleUploadFile = () => {
    fileSelector.click();
  };

  const sendData = async () => {
    try {
      setIsLoading(true);
      const form = new FormData();
      form.append("file", binaryFile);
      let response = {};
      switch (sheetName) {
        case "lesson-data-new":
          response = await uploadLessonData(form);
          break;
        case "lo-upload-new":
          response = await uploadLearningObjectiveData(form);
          break;
        case "FreePlayContent":
          response = await uploadFreePlayData(form);
          break;
        case "ene-data":
          response = await uploadLessonEneData(form);
          break;
          case "guided-reading-data":
            response = await uploadGuidedReadingBookData(form);
            break;
          case "guided-reading-tag":
            response = await uploadGRTagData(form);
            break;
        case "BaselineAssessmentData":
          response = await uploadBaselineData(form);
          break;
        case "OfflineBaseline":
          response = await uploadBaselineOfflineData(form);
          break;
        case "content-meta-data":
          response = await uploadContentMetaData(form);
          break;
        default:
          break;
      }
      if (response) {
        if (response.data._statusCode === 200) {
          toast.success("File uploaded successfully");
        } else if (response.data._statusCode === 322) {
          toast.error(response.data._message);
        } else {
          toast.error("Some thing went wrong.");
        }
      }
      handleRemoveFile();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        toast.error(error.response.data._message);
      }
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile({
      data: [],
      fileName: "Upload a file"
    });
  };

  return (
    <>
      <Loader open={isLoading} />
      <div className={classes.uploadComp}>
        <div className={classes.upload}>
          {uploadedFile.fileName !== "Upload a file" ? (
            <>
              <img
                src={cancelUploadedFile}
                style={{
                  cursor: "pointer",
                  width: "14px",
                  height: "14px",
                  margin: "0.35rem"
                }}
                onClick={handleRemoveFile}
              />
              <Tooltip title={uploadedFile.fileName} placement="top">
                <span className={classes.placeholder}>
                  {uploadedFile.fileName}
                </span>
              </Tooltip>

              <div className={classes.uploadIcon} onClick={sendData}>
                <MailOutlineIcon
                  style={{
                    fontSize: "27px"
                  }}
                />
                <span>Send</span>
              </div>
            </>
          ) : (
            <>
              <span className={classes.placeholder}>Upload a file</span>
              <div className={classes.uploadIcon} onClick={handleUploadFile}>
                <img
                  src={spreadSheetUploadIcon}
                  style={{
                    width: "27px",
                    height: "27px"
                  }}
                />
                <span>Upload</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

BulkUpload.propTypes = {
  fileColumns: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default BulkUpload;
