import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SearchIcon } from "../../../../components/SVGs";
import SelectInput from "../../../../components/SelectInput/index";

const options = [
  { label: "الاسم", value: "name" },
  { label: "ID", value: "id" },
];

const customStyles = {
  divider: {
    height: "70%",
    margin: "5px 0",
    width: "2px",
    backgroundColor: "rgb(217 216 216)",
    borderStyle: "none",
  },
};

const useStyles = makeStyles({
  searchDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "38px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(217, 216, 216, 1)",
    borderRadius: "8px",
    fontSize: "16px",
    cursor: "pointer",
    width: "355px",
    "&:hover": {
      boxShadow: "0 0 6px rgb(98 195 238)",
      borderColor: "rgb(98 195 238)",
    },
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
    "& *": {
      border: "none",
      fontSize: "16px",
    },
    position: "relative",
  },
});

const SearchSchool = ({ search }) => {
  const classes = useStyles();
  const [searchType, setSearchType] = useState(options[0].value);
  const [searchStr, setSearchStr] = useState(null);

  useEffect(() => {
    search(searchType, searchStr);
  }, [searchType, searchStr]);

  const handleSearch = (event) => {
    setSearchStr(event.target.value);
  };

  const handleChangeSearch = ({ currentTarget }) => {
    setSearchType(currentTarget.value);
  };

  return (
    <div className={classes.searchDiv}>
      <div className={classes.searchText}>
        <SearchIcon
          style={{ width: "26px", height: "26px", marginRight: "16px" }}
        />
        <input type="text" placeholder="بحث" onChange={handleSearch} />
      </div>
      <Divider sx={customStyles.divider} orientation="vertical" />
      <SelectInput
        defaultValue={searchType}
        valueList={options}
        getOption={handleChangeSearch}
        plain
      />
    </div>
  );
};

SearchSchool.propTypes = {
  search: PropTypes.func,
};

export default SearchSchool;
