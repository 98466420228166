import React from "react";

// services
import LearningContentService from "services/LearningContentService";

import { toast } from "react-toastify";

// core components
import DataDisplay from "../../../components/DataDisplayView/DataDisplay";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";

const messages = defineMessages(Translations);

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  grBookList: [],
  grBookId: "",
  grBookContentId: "",
  grBookText: "",
  grBookContents: [],
  grBookTags: [],
  alert: null,
  showSnackBar: false,
  snackBarColor: "",
  snackBarMessage: "",
  openassignClassDialog: false,
  btnDisabled: false
};

class GuidedReading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      grBookList: [],
      grBookListWithoutFilter: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50]
    };
  }

  componentDidMount() {
    this.getGuidedReadingBookData();
  }

  getGuidedReadingBookData = _ => {
    this.setState({ open: false, isLoading: true });
    LearningContentService.fetchAllgetGuidedReadingBookData()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              grBookList: response._entity,
              grBookListWithoutFilter: response._entity
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState(
          {
            isLoading: false,
            fetchData: false
          },
          () => {
            toast.error(errorMessage);
          }
        );
      });
  };

  onSearch = e => {
    const { grBookListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = grBookListWithoutFilter.filter(
      x => x._grBookContentId && x._grBookContentId.toLowerCase().indexOf(value) !== -1
    );
    this.setState({ grBookList: filteredData });
  };

  render() {
    const { isLoading, grBookList } = this.state;

    //Default List
    let tbContentList = [];
    if (typeof grBookList !== "undefined" && grBookList.length > 0) {
      tbContentList = grBookList.map(item => {
        const { _grBookId, _grBookContentId, _grBookName, _grBookContentIds, _grBookTags } = item;
        return [
          _grBookId,
          _grBookContentId,
          _grBookName,
          <div>
            {_grBookContentIds &&
              _grBookContentIds.map(item => {
                return <p>{item}</p>;
              })}
          </div>,
          <div>
            {_grBookTags &&
              _grBookTags.map(item => {
                return <p>{item}</p>;
              })}
          </div>
        ];
      });
    }

    return (
      <DataDisplay
        tableHead={[
          "GR Book Id",
          "GR Book Content Id",
          "GR Book Name",
          "GR Book Contents",
          "GR Book Tags"
        ]}
        tableData={tbContentList}
        search={this.onSearch}
        isLoading={isLoading}
        cardIconTitle="Lessons"
        searchPlaceHolderText="Search GR Book"
        uploadButtonText="Upload GR Book Data"
        uploadPath="/guided-reading/bulk-upload"
        colspan={5}
        customClassesForCells={[4]}
        customHeadClassesForCells={[4]}
      />
    );
  }
}

export default injectIntl(GuidedReading);
