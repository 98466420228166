import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const eneColumns = [
  "LESSON_CONTENT_ID",
  "ENE_CONTENT_ID",
  "ENH1",
  "ENR1",
  "ENR2"
];

function EneUpload({}) {
  return <BulkUpload fileColumns={eneColumns} sheetName="ene-data" />;
}

export default EneUpload;
