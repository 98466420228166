import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import closeIcon from "assets/img/close.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  header: {
    paddingRight: "19px",
    height: 60,
    backgroundColor: "rgba(98, 195, 238, 1)",
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(255, 255, 255)",
    fontSize: "1.1rem",
    direction: "ltr",
  },
  heading: {
    marginTop: "13px",
    textAlign: "center",
    flex: 1,
  },
  closeIcon: {
    cursor: "pointer",
  },
});

const PopUpHeader = ({ onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.closeIcon} onClick={onClose}>
        <img src={closeIcon} alt="cross icon" />
      </div>
      <div className={classes.heading}>
        <p> أضف مدرسة</p>
      </div>
    </div>
  );
};

PopUpHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PopUpHeader;
