import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

export function SearchIcon(props) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 28">
      <g fill="none" fillRule="evenodd" transform="translate(0 .817)">
        <path d="M0 0h26.4v26.4H0z" />
        <circle
          cx="11"
          cy="11"
          r="7.7"
          stroke="#62C3EE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
        <path
          stroke="#62C3EE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
          d="M23.1 23.1l-6.6-6.6"
        />
      </g>
    </SvgIcon>
  );
}
SearchIcon.propTypes = {
  color: PropTypes.string,
};

export function ArrowDropDownIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 9">
      <path
        d="M7.255 10.36l6.19-9.354a.336.336 0 00.02-.333A.308.308 0 0013.19.5H.81a.308.308 0 00-.275.173.336.336 0 00.02.333l6.19 9.355c.058.087.153.139.255.139a.307.307 0 00.255-.14z"
        fill={props.style.color || "#62C3EE"}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}
