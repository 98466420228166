import {
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
} from "../../assets/js/sms";

const snackbarContentStyle = {
  root: {
    ...defaultFont,
    flexWrap: "unset",
    position: "relative",
    padding: "20px 15px",
    lineHeight: "20px",
    marginBottom: "20px",
    fontSize: "14px",
    backgroundColor: "white",
    color: "#555555",
    boxShadow:
      "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)",
    border: "1px solid #b1e1ae",
    borderRadius: "8px",
  },
  top20: {
    top: "20px",
  },
  top40: {
    top: "40px",
  },
  info: {
    backgroundColor: "#00d3ee",
    color: "#ffffff",
    ...infoBoxShadow,
  },
  success: {
    backgroundColor: "#e6f9e9",
    color: "#ffffff",
    ...successBoxShadow,
  },
  warning: {
    backgroundColor: "#ffa21a",
    color: "#ffffff",
    ...warningBoxShadow,
  },
  danger: {
    //backgroundColor: "#f55a4e",
    //color: "#ffffff",
    //backgroundColor: "#e6f9e9",
    color: "#000",
    ...dangerBoxShadow,
  },
  primary: {
    backgroundColor: "#af2cc5",
    color: "#ffffff",
    ...primaryBoxShadow,
  },
  rose: {
    backgroundColor: "#eb3573",
    color: "#ffffff",
    ...roseBoxShadow,
  },
  message: {
    padding: "0",
    display: "block",
    maxWidth: "89%",
  },
  close: {
    width: "11px",
    height: "11px",
  },
  iconButton: {
    width: "24px",
    height: "24px",
    padding: "0",
  },
  icon: {
    width: "38px",
    height: "38px",
    display: "block",
    left: "15px",
    position: "absolute",
    marginTop: "-39px",
    fontSize: "20px",
    backgroundColor: "#FFFFFF",
    padding: "9px",
    borderRadius: "50%",
    maxWidth: "38px",
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  infoIcon: {
    color: "#00d3ee",
  },
  successIcon: {
    color: "#5cb860",
  },
  warningIcon: {
    color: "#ffa21a",
  },
  dangerIcon: {
    color: "#f55a4e",
  },
  primaryIcon: {
    color: "#af2cc5",
  },
  roseIcon: {
    color: "#eb3573",
  },
  iconMessage: {
    paddingLeft: "50px",
    display: "block",
  },
  errorMessage: {
    fontFamily: "dubai-bold",
    fontSize: "14px",
    color: "rgba(81, 81, 81, 1)",
    fontWeight: "bold",
    paddingRight: localStorage.getItem("language") === "ar" ? "45px" : "0px",
    paddingLeft: localStorage.getItem("language") === "en" ? "35px" : "0px",
  },
  errorAlert: {
    fontFamily: "dubai-regular",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 1)",
    "& > img": {
      marginLeft: localStorage.getItem("language") === "ar" ? "20px" : "0px",
      marginRight: localStorage.getItem("language") === "en" ? "10px" : "0px",
    },
  },
  closeButton: {
    position: "absolute",
    top: "0",
    margin: "10px",
    cursor: "pointer",
  },
};

export default snackbarContentStyle;
