import phonecodes from "../common/activePhoneCode";

export function removeTrailingZero(value){
  value = value.replace(/^0+/, '');
  return value;
}


export function phoneCodeFormatedForDropDown() {
  /*eslint-disable */
  return phonecodes.map(code => {
    return {
      value: code.dial_code,
      text: code.dial_code
    };
  });
}

export function gradesFormatedFotDropDown(grades) {
  if (!grades) {
    return [];
  }
  const gradeList = grades.filter(grade => grade._code === "Kindergarten 1" || grade._code === "Kindergarten 2");
  return gradeList.map(grade => {
    return {
      value: grade._id,
      text: grade._code
    };
  });
}

export function studentDropDown(students, showAll = false) {
  if (!students) {
    return [];
  }
  if (!students._profiles) {
    return [];
  }

  const studentList = showAll
    ? students._profiles
    : students._profiles.filter(student => typeof student._name === "string");
  return studentList.map(s => {
    return {
      key: s._id,
      value: s._id,
      text: s._name,
      gender: s._gender,
      dob: s._dob
    };
  });
}

export function contentDropDown(contents) {
  if (!contents) {
    return [];
  }
  return contents.map(content => {
    return {
      key: content._id,
      value: content._id,
      text: content._contentName
    };
  });
}
