// Grades
export const GRADES_URL = "/cms/grades"
export const GRADE_URL = "/cms/grade"

// Learning Area
export const LEARNING_AREAS_URL = "/cms/learning-areas"
export const LEARNING_AREA_URL = "/cms/learning-area"

// Default Knowledge Graphs
export const KNOWLEDGE_GRAPHS_URL = "ams/teacher/kg"
export const KNOWLEDGE_GRAPH_URL = "ams/teacher/kg"

// Learning Objectives
export const LEARNING_OBJECTIVES_URL = "/cms/learning-objectives"
export const LEARNING_OBJECTIVE_URL = "/cms/learning-objective"
export const LO_CONTENT_URL = "/cms/lo"

// Classes
export const CLASS_URL = "ams/class"

// Schools
export const SCHOOL_URL = "/ams/school"

//Teachers
export const TEACHERS_URL = "ams/teacher"

//Students
export const STUDENT_URL = "ams/student"

//Curriculum
export const CURRICULUM_URL = "/ams/kg"

//CMS Curriculum
export const CMS_CURRICULUM_URL = "/cms/kg"

//Assessment
export const ASSESSMENT_URL = "web/ams/"

//Plan
export const PLAN_URL = "ams/plan/"

// APP VERSION
export const APP_VERSION = "1.0.7"

