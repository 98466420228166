import React from "react";
import BulkUpload from "../../../../components/BulkUpload/BulkUpload";

const freeplayColumns = ["content_id", "grade"];

function FreePlayUpload({}) {
  return (
    <BulkUpload fileColumns={freeplayColumns} sheetName="FreePlayContent" />
  );
}

export default FreePlayUpload;
