import { dangerColor, successColor, defaultFont } from "../../assets/js/sms";

const customInputStyle = {
  disabled: {
    // by Manoj
    // "&:before": {
    //     borderColor: "transparent !important"
    // }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      // by Manoj
      // borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      // by Manoj
      // borderColor: primaryColor
      borderColor: "rgba(98, 195, 238, 1)",
    },
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor,
    },
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    right: 0,
    left: "auto",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelRootError: {
    color: dangerColor + " !important",
  },
  labelRootSuccess: {
    // By Manoj
    //color: successColor + " !important"
  },
  formControl: {
    // margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057",
    },
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: "#FFFFFF",
    },
    "&:after": {
      backgroundColor: "#FFFFFF",
    },
  },
  input: {
    color: "#495057",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: "#AAAAAA",
    },
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1",
    },
  },
};

export default customInputStyle;
