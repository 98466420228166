import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import DateTimePicker from "../../../../components/DateTimePicker";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "../../../../components/CustomButtons/Button";
import Joi from "joi";
import SchoolService from "../../../../services/SchoolService";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import SchoolSelect from "./SchoolSelect";
import CountryCodeSelect from "./CountryCodeSelect";
import { validationSchema, requiredSchema } from "./Schema";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "rgb(255, 255, 255)",
    borderBottomLeftRadius: "17px",
    borderBottomRightRadius: "17px",
    height: "545px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
  formFields: {
    padding: "0 15px",
    marginTop: "25px",
    flex: 1,
  },
  submit: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  helperText: {
    color: "#f44336",
    fontSize: "0.75rem",
    textAlign: "left",
  },
  sendBtn: {
    color: "rgb(255 255 255)",
    backgroundColor: "#62c3ee",
    width: "97px",
    height: "45px",
    margin: "0 0.5rem",
    "&:hover": {
      backgroundColor: "#86bd30",
    },
  },
  closeBtn: {
    color: "rgb(255 255 255)",
    backgroundColor: "#697b8c",
    width: "97px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#f65d71",
    },
  },
});

const errorCodes = {
  400: {
    type: "error",
    message: "Email Id or Mobile number already exist",
  },
  606: {
    type: "error",
    message:
      "Academic year end date must be 42 weeks greater than Academic year start date",
  },
  609: {
    type: "error",
    message: "Principal email id already exist",
  },
  611: {
    type: "error",
    message:
      "Country code is required if sending mobile number in request for principal and vice versa.",
  },
  612: {
    type: "error",
    message:
      "Country code is required if sending mobile number in request for leader and vice versa.",
  },
  613: {
    type: "error",
    message: "Both email and mobile number cannot be blank for Principal.",
  },
  614: {
    type: "error",
    message: "Both email and mobile number cannot be blank for Principal.",
  },
};

const schoolTypeOptions = [
  { label: "ADEK", value: "ADEK" },
  { label: "ALEF-REDIRECTED", value: "ALEF-REDIRECTED" },
  { label: "DIRECT", value: "DIRECT" },
  { label: "TEST", value: "TEST" },
];

const initialState = {
  schoolName: "",
  firstName: "",
  lastName: "",
  emailId: "",
  mobileNumber: "",
  principalFirstName: "",
  principalLastName: "",
  principalEmailId: "",
  principalMobileNumber: "",
  educationalYearStartDate: null,
  educationalYearEndDate: null,
  term1: null,
  term2: null,
  selectedOption: {},
  principalSelectedOption: {},
  selectedSchoolTypeOption: schoolTypeOptions[0],
};

const daysAddedForTerms = 1;

function Content({ onClose, schoolData, callingCodes, action }) {
  const [formData, setFormData] = useState(initialState);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [minDate, setMinDate] = useState(new Date());
  const [maxTermsDate, setMaxTermsDate] = useState(new Date());
  const [term1, setTerm1] = useState(new Date());
  const [term2, setTerm2] = useState(new Date());
  const classes = useStyles();

  useEffect(() => {
    let data = { ...formData };
    let selectedCode = {};
    selectedCode = callingCodes.filter((data) => data.value === "+971")[0];
    if (action === "edit") {
      data = { ...schoolData };
      if (schoolData.schoolPrincipalCountryCode)
        selectedCode = callingCodes.filter(
          (data) => data.value === schoolData.schoolPrincipalCountryCode
        )[0];
      data = { ...schoolData };
      data.educationalYearStartDate = new Date(
        schoolData.educationalYearStartDate
      );
      data.educationalYearEndDate = new Date(schoolData.educationalYearEndDate);
      data.term1 = schoolData.term1 ? new Date(schoolData.term1) : null;
      data.term2 = schoolData.term2 ? new Date(schoolData.term2) : null;
      setMinDate(
        addDaysToDate(new Date(schoolData.educationalYearStartDate), 42 * 7)
      );
      setTerm1(
        addDaysToDate(
          new Date(schoolData.educationalYearStartDate),
          daysAddedForTerms
        )
      );
      setTerm2(addDaysToDate(new Date(schoolData.term1), daysAddedForTerms));
      setMaxTermsDate(new Date(schoolData.educationalYearEndDate));
      setEdit(true);
    }
    data["selectedOption"] = selectedCode;
    data["principalSelectedOption"] = selectedCode;
    setFormData(data);
  }, []);

  const addDaysToDate = (dt, days) => {
    return new Date(dt.setDate(dt.getDate() + days));
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const propertySchema = {
      [name]: validationSchema[name],
    };
    const { error } = Joi.object(propertySchema).validate(obj);
    return error ? error.details[0].message : null;
  };

  const handleChange = (event) => {
    const { currentTarget: input } = event;
    if (
      (input.name === "emailId" || input.name === "principalEmailId") &&
      input.value.length > 0
    )
      input.value = input.value.trim();
    const errorsObject = { ...errors };
    const errorMesssage = validateProperty(input);
    if (errorMesssage) {
      errorsObject[input.name] = errorMesssage;
      console.log(errorsObject);
    } else {
      if (input.name === "emailId") {
        if (
          formData.mobileNumber === "" ||
          (formData.mobileNumber &&
            !errorsObject.hasOwnProperty("mobileNumber"))
        )
          delete errorsObject["mobileNumber"];
      } else if (input.name === "mobileNumber") {
        if (
          formData.emailId === "" ||
          (formData.emailId && !errorsObject.hasOwnProperty("emailId"))
        )
          delete errorsObject["emailId"];
      }
      delete errorsObject[input.name];
    }
    if (
      ((input.name === "principalMobileNumber" ||
        input.name === "principalEmailId") &&
        input.value === "") ||
      ((input.name === "principalFirstName" ||
        input.name === "principalLastName") &&
        input.value === "" &&
        formData.principalMobileNumber === "" &&
        formData.principalEmailId === "")
    ) {
      delete errorsObject["principalFirstName"];
      delete errorsObject["principalLastName"];
    }
    setErrors(errorsObject);
    const data = { ...formData };
    data[input.name] = input.value;
    setFormData(data);
  };

  const handleSchoolSelect = (selectedSchoolTypeOption) => {
    const data = { ...formData };
    data["selectedSchoolTypeOption"] = selectedSchoolTypeOption;
    setFormData(data);
  };

  const formatDate = (date) => {
    if (date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return null;
  };

  const setStartDate = (date, type) => {
    const data = { ...formData };
    data[type] = date;
    const errorsObject = { ...errors };
    delete errorsObject[type];
    if (type === "educationalYearStartDate") {
      data["educationalYearEndDate"] = "";
      data["term1"] = "";
      data["term2"] = "";
      const startDate = new Date(date);
      const termOne = new Date(date);
      const termTwo = new Date(date);
      let minDate = new Date(date);
      minDate.setDate(startDate.getDate() + 42 * 7);
      termOne.setDate(startDate.getDate() + daysAddedForTerms); //30
      termTwo.setDate(termOne.getDate() + daysAddedForTerms + 1); //30
      setMaxTermsDate(new Date(minDate));
      setMinDate(minDate);
      setTerm1(termOne);
      setTerm2(termTwo);
    }
    if (type === "term1") {
      data["term2"] = "";
      const termOne = new Date(date);
      const termTwo = new Date(date);
      termTwo.setDate(termOne.getDate() + daysAddedForTerms); //30
      setTerm2(termTwo);
    }
    if (type === "educationalYearEndDate") {
      setMaxTermsDate(new Date(date));
      data["term1"] = "";
      data["term2"] = "";
    }
    setErrors(errorsObject);
    setFormData(data);
  };

  const handleCountryInput = (selectedOption, name) => {
    const data = { ...formData };
    data[name] = selectedOption;
    setFormData(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (formData.schoolLeaderId) updateSchoolData();
    else addNewSchool();
  };

  const validate = () => {
    const {
      schoolName,
      firstName,
      lastName,
      emailId,
      mobileNumber,
      schoolPrincipalUserId,
      principalFirstName,
      principalLastName,
      principalEmailId,
      principalMobileNumber,
      educationalYearStartDate,
      educationalYearEndDate,
      term1,
    } = formData;

    const options = { abortEarly: false };
    const { error } = Joi.object(validationSchema).validate(
      {
        mobileNumber,
        schoolName,
        firstName,
        lastName,
        emailId,
        principalFirstName,
        principalLastName,
        principalEmailId,
        principalMobileNumber,
        educationalYearStartDate: educationalYearStartDate
          ? educationalYearStartDate.toLocaleDateString("en-GB")
          : "",
        educationalYearEndDate: educationalYearEndDate
          ? educationalYearEndDate.toLocaleDateString("en-GB")
          : "",
        term1: term1 ? term1.toLocaleDateString("en-GB") : "",
      },
      options
    );
    const requiredError = Joi.object(requiredSchema).validate(
      { emailId, mobileNumber },
      options
    );
    let errorsObject = { ...errors };
    if (error) {
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
      if (!errorsObject.hasOwnProperty("error")) {
        if (principalEmailId === "" && principalMobileNumber === "") {
          delete errorsObject["principalFirstName"];
          delete errorsObject["principalLastName"];
        }
      }
      if (!requiredError.hasOwnProperty("error")) {
        if (
          emailId === "" ||
          (emailId && !errorsObject.hasOwnProperty("emailId"))
        )
          delete errorsObject["emailId"];
        if (
          mobileNumber === "" ||
          (mobileNumber && !errorsObject.hasOwnProperty("mobileNumber"))
        )
          delete errorsObject["mobileNumber"];
      }
    }
    if (
      !errorsObject["principalEmailId"] &&
      !errorsObject["principalMobileNumber"]
    )
      if (
        (((principalFirstName && principalFirstName.length) ||
          (principalLastName && principalLastName.length)) &&
          !principalEmailId.length &&
          !principalMobileNumber.length &&
          !schoolPrincipalUserId) ||
        (schoolPrincipalUserId &&
          !principalEmailId.length &&
          !principalMobileNumber.length)
      ) {
        errorsObject["principalEmailId"] = "email or mobile is required";
        errorsObject["principalMobileNumber"] = "email or mobile is required";
      } else {
        delete errorsObject["principalEmailId"];
        delete errorsObject["principalMobileNumber"];
      }

    return errorsObject;
  };

  const addNewSchool = async () => {
    const data = getPayload();
    try {
      const response = await SchoolService.addSchool(data);
      if (response && parseInt(response._statusCode) === 200) {
        toast.success("Success");
        succcesCallBack();
      } else throw response;
    } catch (error) {
      showAlertError(error);
    }
  };

  const updateSchoolData = async () => {
    const data = getPayload(true);
    try {
      const response = await SchoolService.updateSchool(data);
      if (response && parseInt(response._statusCode) === 200) {
        toast.success("Success");
        succcesCallBack();
      } else throw response;
    } catch (error) {
      showAlertError(error);
    }
  };

  const getPayload = (isUpdate = false) => {
    let _school = {
      _schoolName: formData.schoolName,
      _educationalYearStartDate: formatDate(formData.educationalYearStartDate),
      _educationalYearEndDate: formatDate(formData.educationalYearEndDate),
      _termOneEndDate: formatDate(formData.term1),
      _termTwoEndDate: formatDate(formData.term2),
      _isActive: true,
      _schoolLogoUrl: formData.selectedSchoolTypeOption.value,
    };
    _school = isUpdate ? { ..._school, _schoolId: formData.schoolId } : _school;
    let _leader = {
      _firstName: formData.firstName,
      _lastName: formData.lastName,
    };
    _leader = isUpdate
      ? _leader
      : {
          ..._leader,
          _mobileNumber: formData.mobileNumber,
          _countryCode: formData.mobileNumber
            ? formData.selectedOption.value
            : "",
          _email: formData.emailId,
        };

    let data = {
      _school,
      _leader,
    };
    if (formData.principalMobileNumber || formData.principalEmailId) {
      let _principal = {
        _firstName: formData.principalFirstName,
        _lastName: formData.principalLastName,
        _mobileNumber: formData.principalMobileNumber,
        _countryCode: formData.principalMobileNumber
          ? formData.principalSelectedOption.value
          : "",
        _email: formData.principalEmailId,
      };
      data["_principal"] = _principal;
    }
    return data;
  };

  const showAlertError = (error) => {
    if (errorCodes[error._statusCode])
      toast[errorCodes[error._statusCode]["type"]](
        errorCodes[error._statusCode]["message"]
      );
    else toast.error("There is a technical problem. Please try again later");
    console.log({ error });
  };

  const succcesCallBack = () => {
    setFormData(initialState);
    onClose();
  };

  const renderInput = (name, validationMessage, type = "text") => {
    return (
      <TextField
        label={validationMessage}
        variant="outlined"
        size="small"
        inputProps={{
          type: type,
          name: name,
          value: formData[name],
          disabled:
            edit && (name === "emailId" || name === "mobileNumber")
              ? true
              : false,
          onChange: (event) => handleChange(event, name),
        }}
        // helperText={errors[name]}
        error={errors[name] && errors[name].length > 0}
      />
    );
  };

  return (
    <div className={classes.root}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("schoolName", "School Name")}
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              <SchoolSelect
                schoolTypeOptions={schoolTypeOptions}
                selectedSchool={formData.selectedSchoolTypeOption}
                handleSchoolSelect={handleSchoolSelect}
                disabled={edit}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("firstName", "Leader First Name")}
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("lastName", "Leader Second Name")}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            <Grid container className={classes.container}>
              <Grid item md={edit ? 12 : 7}>
                <FormControl fullWidth>
                  {renderInput("mobileNumber", "Leader Mobile")}
                </FormControl>
              </Grid>
              {!edit && (
                <Grid item md={5} style={{ paddingRight: "15px" }}>
                  <FormControl fullWidth>
                    <CountryCodeSelect
                      onChange={(value) =>
                        handleCountryInput(value, "selectedOption")
                      }
                      selectedCode={formData.selectedOption}
                      options={callingCodes}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("emailId", "Leader Email")}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("principalFirstName", "Principal First Name")}
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("principalLastName", "Principal Second Name")}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            <Grid container className={classes.container}>
              <Grid item md={7}>
                <FormControl fullWidth>
                  {renderInput("principalMobileNumber", "Principal Mobile")}
                </FormControl>
              </Grid>

              <Grid item md={5} style={{ paddingRight: "15px" }}>
                <FormControl fullWidth>
                  <CountryCodeSelect
                    onChange={(value) =>
                      handleCountryInput(value, "principalSelectedOption")
                    }
                    selectedCode={formData.principalSelectedOption}
                    options={callingCodes}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            <FormControl fullWidth>
              {renderInput("principalEmailId", "Principal Email")}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            {renderDatePicker(
              "educationalYearStartDate",
              "Academic Year Start Date"
            )}
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            {renderDatePicker(
              "educationalYearEndDate",
              "Academic Year End Date",
              minDate
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item md={6} className={classes.formFields}>
            {renderDatePicker("term1", "Term 1 End Date", term1, maxTermsDate)}
          </Grid>
          <Grid item md={6} className={classes.formFields}>
            {renderDatePicker("term2", "Term 2 End Date", term2, maxTermsDate)}
          </Grid>
        </Grid>

        <Grid container className={classes.submit}>
          <Grid item>
            <Button
              className={classes.sendBtn}
              type="submit"
              onClick={handleSubmit}
              disabled={Object.keys(validate()).length > 0}
            >
              {edit ? "Update" : "Add"}
            </Button>
            <Button onClick={onClose} className={classes.closeBtn}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  function renderDatePicker(name, label, mindate = null, maxdate = null) {
    return (
      <DateTimePicker
        error={Object.keys(errors).length && errors[name] ? true : false}
        placeHolder={label}
        minDate={mindate}
        maxDate={maxdate}
        startDate={formData[name]}
        setStartDate={(date) => setStartDate(date, name)}
      />
    );
  }
}

export default Content;
