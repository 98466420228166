import axios from "axios";
import { toast } from "react-toastify";

let isRefreshRequested = false;

let API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": process.env.REACT_APP_ACL_ORIGIN,
    "Content-type": "application/json",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
});

API.interceptors.request.use(function (config) {
  const token = localStorage.getItem("abjadToken");
  config.headers.Authorization = token ? token : "";
  return config;
});

function redirectToLogin() {
  isRefreshRequested = false;
  localStorage.clear();
  toast.error("انتهت صلاحية جلسة الدخول");
  setTimeout(() => {
    window.location = "/login";
  }, 1000);
}

const getNewAccessToken = async (_refreshToken) => {
  return API.post("/auth/refresh-token", {
    _refreshToken,
  })
    .then((response) => response.data)
    .catch((error) => error.response);
};

API.interceptors.response.use(
  async (response) => {
    const { data, config: issuedRequest } = response;
    const refreshToken = localStorage.getItem("refreshToken");
    if (data._statusCode === 403 && refreshToken && !isRefreshRequested) {
      isRefreshRequested = true;
      const res = await getNewAccessToken(refreshToken);
      if (res._statusCode === 200) {
        const { _accessToken } = res._entity;
        localStorage.setItem("abjadToken", _accessToken);
        issuedRequest.headers.Authorization = _accessToken;
        isRefreshRequested = false;
        return API(issuedRequest);
      }
    }

    if (data._statusCode === 401) {
      redirectToLogin();
    }
    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (!expectedError) {
      console.log(error);
      toast.error("هناك مشكلة فنية، الرجاء المحاولة لاحقاً");
    }
    return Promise.reject(error);
  }
);

export default API;
