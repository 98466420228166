import API from "./api";

class SchoolService {
  getAllSchools = async () => {
    return API.get("/cms/school")
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  addSchool = async (data) => {
    return API.post("/cms/school/leader-principal", data)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  updateSchool = async (data) => {
    return API.put("/cms/school/leader-principal", data)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  deleteSchool = async (data) => {
    return API.put("/cms/school/leader", data)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  switchRestrictedUse = async (data) => {
    return API.put("/cms/school/switch-restriction", data)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  performSchoolRollover = async (data) => {
    return API.post("/cms/school/archive", data)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  getCountryCallingCodesList = async () => {
    return API.get(`ams/countries`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };
}

export default new SchoolService();
