import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import AvatarMenu from "./AvatarMenu";
import { BigLogoIcon } from "./svgIcons";
import MobileMenu from "./MobileMenu";
import { makeStyles } from "@material-ui/core/styles";
import CacheService from "../../../services/CacheService";

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(255 255 255)",
    transition: "all 150ms ease 0s",
    "@media print": {
      display: "none"
    },
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)"
  },

  container: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerLogo: {
    fontSize: "9vw"
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:992px)": {
      display: "none"
    }
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.5vw",
    fontWeight: "bold",
    color: "rgb(131 131 131)",
    "& *": {
      minWidth: "unset",
      padding: "0.25rem"
    },
    "& .active": {
      color: "rgb(98 195 238)"
    }
  },
  help: {
    color: "rgb(131 131 131)",
    fontSize: "2.5vw",
    cursor: "pointer",
    "& :hover": {
      color: "rgb(98 195 238)"
    }
  },
  actionIcons: {
    display: "flex",
    alignItems: "center"
  }
});

const Header = ({ routes }) => {
  const classes = useStyles();

  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    setRoleId(
      CacheService.onGetItem("roleId") &&
        parseInt(CacheService.onGetItem("roleId"))
    );
  }, [roleId]);

  return (
    <AppBar className={classes.appBar} id="appBar">
      <Toolbar className={classes.container}>
        <BigLogoIcon className={classes.headerLogo} />
        <MobileMenu routes={routes} roleId={roleId} />
        <div className={classes.actionIcons}>
          <AvatarMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

Header.propTypes = {
  routes: PropTypes.array.isRequired
};
