import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "../../components/Header/Public";

import routes from "../../routes/public";
import layoutsStyle from "./layoutsStyle";

class Layouts extends React.Component {
  constructor() {
    super();
    this.state = {
      // direction: localStorage.getItem("direction"),
      // language: localStorage.getItem("language"),
      direction: "rtl",
      language: "ar",
    };
    this.onVisibilitychange = this.onVisibilitychange.bind(this);
  }

  componentDidMount() {
    if (
      localStorage.getItem("abjadToken") &&
      localStorage.getItem("abjadToken") !== "undefined"
    ) {
      this.props.history.replace("/");
    }
    document.body.style.overflow = "unset";
    document.addEventListener("visibilitychange", this.onVisibilitychange);
  }

  onVisibilitychange = () => {
    //if (localStorage.getItem("abjadToken")) this.props.history.replace('/plan');
  };

  switchLanguage(params) {
    this.setState({
      language: params,
    });
    localStorage.setItem("language", params);
    window.location.reload();
  }

  switchDirection(dir) {
    this.setState({
      direction: dir,
    });
    localStorage.setItem("direction", dir);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          callback={this.switchLanguage.bind(this)}
          directionCallback={this.switchDirection.bind(this)}
          language={this.state.language}
          direction={this.state.direction}
          {...rest}
        />
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage}
            //style={{ backgroundImage: "url('https://s3.ap-south-1.amazonaws.com/teacher-assets/blue_pattern.jpg')" }}
          >
            <Switch>
              {routes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
            {/* <Footer white /> */}
          </div>
        </div>
      </div>
    );
  }
}

Layouts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(layoutsStyle)(Layouts);
