import API from './api';

class UserService {
  checkUserAndSendOtp = async (data) => {
    return API
      .post('/ams/login', data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  registerGuestUser = async (data) => {
    return API
      .post('/ams/register', data)
      .then(response => response.data)
      .catch(error => error.response)
  }
  verifyOtp = async (data) => {
    return API
      .post('/ams/verify-otp', data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  resendPassword = async (data) => {
    return API
      .post('/ams/resend-password', data)
      .then(response => response.data)
      .catch(error => error.response)
  };
  getTeacherPinByAbjadId = async (teacherAbjadId) => {
    return API
      .get('/ams/user-pin/'+teacherAbjadId)
      .then(response => response.data)
      .catch(error => error.response)
  }
}

export default new UserService();
