import React, { Fragment } from "react";

// services
import LearningContentService from "services/LearningContentService";
import { clearContentMetaDataCache } from "services/UploadService";
import DataDisplay from "../../../components/DataDisplayView/DataDisplay";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";

// style
import contentStyle from "./contentStyle";
import phoneCode from "../../../common/activePhoneCode";
import { removeTrailingZero } from "../../../core/selectors";

import { injectIntl, defineMessages } from "react-intl";
import Translations from "../content.json";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const messages = defineMessages(Translations);

const options = phoneCode.map(isdCode => {
  return {
    label: isdCode.dial_code,
    value: isdCode.dial_code
  };
});

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

const INITIAL_STATE = {
  open: false,
  isLoading: false,
  action: "",
  contentId: "",
  contentType: "",
  contentName: "",
  contentProviderId: "",
  thumbUrl: "",
  url: "",
  streamUrl: "",
  educationalConcept: "",
  alert: null,
  showSnackBar: false,
  snackBarColor: "",
  snackBarMessage: "",
  openassignClassDialog: false,
  btnDisabled: false
};

class ContentMetaData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      direction: localStorage.getItem("direction"),
      language: localStorage.getItem("language"),
      learningContentList: [],
      page: 0,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.btnActionClick = this.btnActionClick.bind(this);
  }

  componentDidMount() {
    this.getLearningContent();
  }

  getLearningContent = _ => {
    this.setState({ open: false, isLoading: true });
    LearningContentService.getLearningContent()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200)
          response._entity &&
            this.setState({
              learningContentList: response._entity,
              learningContentListWithoutFilter: response._entity.sort(
                (a, b) => b._createdAt - a._createdAt
              )
            });
        else throw response;
      })
      .then(_ => this.setState({ isLoading: false }))
      .catch(e => this.showErrorMessage());
  };

  showNotification() {
    this.setState({ showSnackBar: true });
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ showSnackBar: false });
      }.bind(this),
      6000
    );
  }

  handleSubmit = action => {
    const {
      intl: { formatMessage }
    } = this.props;
    const {
      contentId,
      contentType,
      contentName,
      contentProviderId,
      thumbUrl,
      url,
      streamUrl
    } = this.state;
    const data = {
      _content: contentId,
      _type: contentType,
      _category: 4,
      _theme: 13,
      _name: contentName,
      _provider: contentProviderId,
      _unlockPrice: 0,
      _url: url,
      _thumbUrl: thumbUrl,
      _keyword: "",
      _streamUrl: streamUrl,
      _coinsReward: 0,
      _pointRewards: 0,
      _version: 0,
      _isDeprecated: "N",
      _minAge: 2,
      _maxAge: 3,
      _difficultyLevel: 200,
      _androidHash: "",
      _iosHash: ""
    };
    if (action === "saveAndAdd") {
      this.setState({ btnDisabled: true });
      LearningContentService.addLearningContent(data)
        .then(response => {
          if (response && parseInt(response._statusCode) === 200) {
            this.setState({
              ...INITIAL_STATE,
              isLoading: false,
              open: true,
              action: "add",
              btnDisabled: false,
              fetchData: true,
              snackBarColor: "success",
              snackBarMessage: "Content Added successfully."
            });
          } else throw response;
        })
        .then(() => this.showNotification())
        .then(() => this.getLearningContent())
        .catch(e => console.log("here", e));
    } else if (action === "add") {
      this.setState({ btnDisabled: true });
      LearningContentService.addLearningContent(data)
        .then(response => {
          console.log("response", response);
          if (response && parseInt(response._statusCode) === 200) {
            this.setState({
              ...INITIAL_STATE,
              isLoading: false,
              open: false,
              snackBarColor: "success",
              btnDisabled: false,
              snackBarMessage: "Content Added successfully."
            });
          } else throw response;
        })
        .then(() => this.showNotification())
        .then(() => this.getLearningContent())
        .catch(e => e => console.log("here", e));
    } else if (action === "edit") {
      LearningContentService.updateLearningContent(data)
        .then(response => {
          if (response && parseInt(response._statusCode) === 200) {
            this.setState({
              ...INITIAL_STATE,
              isLoading: false,
              open: false,
              snackBarColor: "success",
              snackBarMessage: "Content updated successfully."
            });
          } else throw response;
        })
        .then(() => this.showNotification())
        .then(() => this.getLearningContent())
        .catch(e => this.showErrorMessage(e));
    }
    //}
  };

  btnActionClick = (action, item) => {
    const {
      intl: { formatMessage }
    } = this.props;
    const {
      _content,
      _nameEn,
      _type,
      _provider,
      _thumbUrl,
      _url,
      _streamUrl,
      _educationalConcept
    } = item;
    this.setState(
      {
        action,
        contentId: _content,
        contentName: _nameEn,
        contentType: _type,
        contentProviderId: _provider,
        thumbUrl: _thumbUrl,
        url: _url,
        streamUrl: _streamUrl,
        educationalConcept: _educationalConcept
      },
      () => {
        if (action === "delete") {
          const { contentName } = this.state;
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={formatMessage(messages.teachers.sureLabel)}
                onConfirm={() => this.successDelete()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText={formatMessage(messages.teachers.deleteBtnLabel)}
                cancelBtnText={formatMessage(messages.teachers.cancelLabel)}
                showCancel
              >
                {formatMessage(messages.teachers.deleteWarningText)}{" "}
                <strong>{contentName}</strong>
              </SweetAlert>
            )
          });
        } else if (action === "edit") {
          this.setState({ open: true });
        }
      }
    );
  };

  evictCache = () => {
    console.log("here");
    LearningContentService.clearContentMetaDataCache()
      .then(response => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState({
            ...INITIAL_STATE,
            isLoading: false,
            open: false,
            snackBarColor: "success",
            snackBarMessage: "Content Metadata cache cleared successfully."
          });
        } else throw response;
      })
      .then(() => this.showNotification())
      .then(() => this.getLearningContent())
      .catch(e => this.showErrorMessage(e));
  };

  showErrorMessage = error => {
    const {
      intl: { formatMessage }
    } = this.props;
    let errorMessage = "";
    if (error && error._message) errorMessage = error._message;
    else errorMessage = formatMessage(messages.teachers.errorMessageText);
    this.setState(
      {
        isLoading: false,
        fetchData: false
      },
      () => {
        toast.error(errorMessage);
      }
    );
  };

  successDelete = () => {
    const {
      intl: { formatMessage }
    } = this.props;
    const { contentName, contentId } = this.state;
    LearningContentService.deleteLearningContent(contentId)
      .then(response => {
        if (response && parseInt(response._statusCode) === 200) {
          this.setState({
            fetchData: true,
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
              >
                <strong>{contentName}</strong>{" "}
                {formatMessage(messages.teachers.deleteConfirmText)}
              </SweetAlert>
            )
          });
          this.getLearningContent();
        } else throw response;
      })
      .then(() => this.props.onRefresh && this.props.onRefresh("classess"))
      .catch(error => {
        const {
          intl: { formatMessage }
        } = this.props;
        let errorMessage = "";
        if (error && error._message) errorMessage = error._message;
        else errorMessage = formatMessage(messages.teachers.errorMessageText);
        this.setState({
          isLoading: false,
          fetchData: false,
          alert: toast.error(errorMessage)
        });
      });
  };

  hideAlert = () => {
    const { fetchData } = this.state;
    this.setState(
      {
        alert: null
      },
      () => {
        if (fetchData) {
          this.getLearningContent();
        }
      }
    );
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "select":
        this.setState({ [stateName + "State"]: "success" });
        break;
      default:
        break;
    }
    if (stateName === "mobileNumber") {
      this.setState({ [stateName]: removeTrailingZero(event.target.value) });
    } else {
      this.setState({ [stateName]: event.target.value });
    }
  }

  verifyLength(value, length) {
    if (value.trim().length >= length) {
      return true;
    }
    return false;
  }

  onSearch = e => {
    const { learningContentListWithoutFilter } = this.state;
    const value = e.target.value.toLowerCase();
    const filteredData = learningContentListWithoutFilter.filter(
      x =>
        (x._nameEn && x._nameEn.toLowerCase().indexOf(value) !== -1) ||
        (x._content && x._content.toLowerCase().indexOf(value) !== -1) ||
        (x._nameAr && x._nameAr.toLowerCase().indexOf(value) !== -1) ||
        (x._createdAt && x._createdAt.toLowerCase().indexOf(value) !== -1) ||
        (x._updatedAt && x._updatedAt.toLowerCase().indexOf(value) !== -1)
    );
    this.setState({ learningContentList: filteredData });
  };

  render() {
    const {
      classes,
      intl: { formatMessage }
    } = this.props;
    const {
      isLoading,
      teacherName,
      open,
      action,
      openassignClassDialog,
      searchClass,
      btnDisabled,
      learningContentList,
      contentId,
      contentType,
      contentName,
      contentProviderId,
      thumbUrl,
      url,
      streamUrl,
      educationalConcept,
      direction,
      alert
    } = this.state;

    //Default List
    let tbContentList = [];
    if (
      typeof learningContentList !== "undefined" &&
      learningContentList.length > 0
    ) {
      tbContentList = learningContentList.map(item => {
        const {
          _content,
          _category,
          _educationalConcept,
          _url,
          _nameEn,
          _provider,
          _thumbUrl,
          _keyword,
          _type,
          _createdAt,
          _updatedAt
        } = item;
        return [
          _content,
          _nameEn,
          _type,
          _provider,
          _educationalConcept && _educationalConcept.indexOf("http") != -1 ? (
            <video width="320" height="240" controls>
              <source src={_educationalConcept} type="video/mp4" />
            </video>
          ) : (
            _educationalConcept
          ),
          _thumbUrl != "" ? (
            <img src={_thumbUrl} width="100px" height="75px" />
          ) : (
            "no preview available"
          ),
          _createdAt,
          _updatedAt,
          <Fragment>
            {[
              { color: "success", icon: Edit, action: "edit" },
              { color: "danger", icon: Close, action: "delete" }
            ].map((prop, key) => {
              return (
                <Button
                  color={prop.color}
                  className={classes.actionButton}
                  key={key}
                  onClick={() => this.btnActionClick(prop.action, item)}
                >
                  <prop.icon className={classes.icon} />
                </Button>
              );
            })}
          </Fragment>
        ];
      });
    }

    let button;
    if (action === "add") {
      button = (
        <AddButton
          onClickSaveAndNew={() => this.handleSubmit("saveAndAdd")}
          onClickFinish={() => this.handleSubmit("add")}
          disabled={btnDisabled}
          {...this.props}
        />
      );
    } else {
      button = (
        <UpdateButton
          onClickCancel={() => this.setState({ ...INITIAL_STATE })}
          onClickSave={() => this.handleSubmit("edit")}
          {...this.props}
        />
      );
    }
    return (
      <React.Fragment>
        <DataDisplay
          tableHead={[
            "Id",
            "Name",
            "Content Type",
            "Content Provider",
            "Educational Concept",
            "Icon",
            "Created At",
            "Updated At",
            "Actions"
          ]}
          tableData={tbContentList}
          search={this.onSearch}
          searchPlaceHolderText="Search Content"
          cardIconTitle="المدارس"
          uploadButtonText="Upload Content"
          uploadPath="/content-metadata/bulk-upload"
          isLoading={isLoading}
          sweetAlert={alert}
          colspan={9}
          customClassesForCells={[8]}
          customHeadClassesForCells={[8]}
          handleOnclick={this.evictCache}
          buttonLabel="Clear ContentMetadata Cache"
        />
        <Dialog
          classes={{
            root:
              classes.center +
              " " +
              classes.modalRoot +
              " " +
              classes.gradeModal,
            paper: classes.modal
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ ...INITIAL_STATE, open: false });
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="danger"
              onClick={() => {
                this.setState({ ...INITIAL_STATE, open: false });
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h2 className={classes.modalTitle}>إضافة محتوى التعلم</h2>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Content Id"
                    inputProps={{
                      type: "text",
                      name: "contentId",
                      value: contentId,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "contentId", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Content Type"
                    inputProps={{
                      type: "text",
                      name: "contentType",
                      value: contentType,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "contentType", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.mainGrid}>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Content Name"
                    inputProps={{
                      type: "text",
                      name: "contentName",
                      value: contentName,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "contentName", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Content Provider Id"
                    inputProps={{
                      type: "text",
                      name: "contentProviderId",
                      value: contentProviderId,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "contentProviderId", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Thumb Url"
                    inputProps={{
                      type: "text",
                      name: "thumbUrl",
                      value: thumbUrl,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "thumbUrl", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Url"
                    inputProps={{
                      type: "text",
                      name: "url",
                      value: url,
                      dir: direction,
                      onChange: event => this.change(event, "url", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Stream Url"
                    inputProps={{
                      type: "text",
                      name: "streamUrl",
                      value: streamUrl,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "streamUrl", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} lg={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <CustomInput
                    labelText="Educational Concept"
                    inputProps={{
                      type: "text",
                      name: "educationalConcept",
                      value: educationalConcept,
                      dir: direction,
                      onChange: event =>
                        this.change(event, "educationalConcept", "length", 1)
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            {button}
          </DialogContent>
        </Dialog>
        <Dialog
          classes={{
            root:
              this.props.classes.center +
              " " +
              this.props.classes.modalRoot +
              " " +
              this.props.classes.gradeModal,
            paper: this.props.classes.modal
          }}
          open={openassignClassDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({ openassignClassDialog: false }, () =>
              this.getAllTeachersBySchool()
            );
          }}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={this.props.classes.modalHeader}
          >
            <Button
              justIcon
              className={this.props.classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="danger"
              onClick={() =>
                this.setState({ openassignClassDialog: false }, () =>
                  this.getAllTeachersBySchool()
                )
              }
            >
              <Close className={this.props.classes.modalClose} />
            </Button>
            <h2 className={this.props.classes.modalTitle}>
              Assign Classes to Teacher
            </h2>
            <div className={this.props.classes.modalTitle}>
              <div className={classes.teacherTitle}>{teacherName}</div>
            </div>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={this.props.classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} lg={12} md={12}>
                <FormControl
                  className={
                    this.props.classes.selectFormControl +
                    " " +
                    this.props.classes.searchField
                  }
                >
                  <TextField
                    id="searchClass"
                    className={classes.textField}
                    variant="outlined"
                    name="searchClass"
                    value={searchClass}
                    onChange={event =>
                      this.change(event, "searchClass", "length", 1)
                    }
                    style={{ marginBottom: "5%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Search for classes">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

function AddButton(props) {
  const {
    intl: { formatMessage }
  } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Button
          color="warning"
          style={{
            marginTop: "30px",
            marginRight: "10px",
            fontFamily: "Dubai-bold"
          }}
          onClick={props.onClickSaveAndNew}
          disabled={props.disabled}
        >
          {formatMessage(messages.teachers.saveAndNewText)}
        </Button>
        <Button
          color="success"
          style={{
            marginTop: "30px",
            padding: "12px 60px",
            marginLeft: "10px",
            fontFamily: "Dubai-bold"
          }}
          onClick={props.onClickFinish}
          disabled={props.disabled}
        >
          {formatMessage(messages.teachers.finishText)}
        </Button>
      </GridItem>
    </GridContainer>
  );
}

function UpdateButton(props) {
  const {
    intl: { formatMessage }
  } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Button
          color="success"
          style={{
            marginTop: "30px",
            fontFamily: "Dubai-bold",
            fontSize: "16px"
          }}
          onClick={props.onClickSave}
        >
          {formatMessage(messages.teachers.saveLabel)}
        </Button>
      </GridItem>
    </GridContainer>
  );
}

export default injectIntl(withStyles(contentStyle)(ContentMetaData));
